import {Dispatch} from 'redux'
import {AppState} from '../../store/AppState'
import {connect} from 'react-redux'
import {fetchSaJooAnalysis, fetchSampleSaJooAnalysis} from '../../store/sajoo/actions'
import SaJooContainer from './sajoo-container'
import {
  changePayBoxOpenState,
  changePrice,
  changeProduct,
} from '../../store/payment/actions'
import {ProductType} from '../../store/payment/types'
import {changeMoveToAfterLoad} from "../../store/common/actions";

// const mapStateToProps = ({owner}: AppState) => ({
//   owner: owner
// });

const mapStateToProps = ({sajoo,common}: AppState) => ({
  saJooAnalysis: sajoo.saJooAnalysis,
  isLoading: sajoo.isLoading,
  code: sajoo.code,
  moveToAfterLoad: common.moveToAfterLoad,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchSaJooAnalysis: () => dispatch(fetchSaJooAnalysis()),
  fetchSampleSaJooAnalysis: () => dispatch(fetchSampleSaJooAnalysis()),
  openPayBox: () => dispatch(changePayBoxOpenState(true)),
  changePrice: (price: number) => dispatch(changePrice(price)),
  changeProduct: (product: ProductType) => dispatch(changeProduct(product)),
  changeMoveToAfterLoad: (val: boolean) => dispatch(changeMoveToAfterLoad(val)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaJooContainer)

import React from 'react'

const Inspiration = () => (
  <svg
    id="f4906278-e5e1-42d3-b4a5-bfe37cab7be4"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 974.91453 732"
  >
    <title>inspiration</title>
    <path
      d="M562.97712,773.71352q39.72552-5.1817,76.39043-8.80958l-9.40248-15.76427c3.26753-.90967,17.4189,8.21538,17.4189,8.21538l-11.95126-45.5896c16.33378,1.8332,23.92852,48.33181,23.92852,48.33181l18.52227-15.48677-9.12371,17.54106c74.79736-6.53371,139.33828-8.54773,193.59053-8.04394L854.30273,740.615c3.26753-.90966,17.41889,8.21539,17.41889,8.21539l-11.95126-45.5896c16.33378,1.83319,23.92852,48.3318,23.92852,48.3318l18.52227-15.48677-9.66345,18.57885c45.46846,1.2664,82.51651,4.27692,111.09725,7.55735-8.02571-20.96891-36.08514-41.13875-36.08514-41.13875,21.581,5.63464,32.8047,14.79987,38.61167,23.29283a311.42983,311.42983,0,0,0-23.303-126.75549s43.50991,82.07461,36.94278,136.76945l-.83494,9.71591c37.89325,4.9721,56.90615,9.9185,56.90615,9.9185Z"
      transform="translate(-112.54273 -84)"
      fill="#3f3d56"
    />
    <ellipse
      cx="487.45727"
      cy="694.08666"
      rx="487.45727"
      ry="37.91334"
      fill="#3f3d56"
    />
    <path
      d="M612.18643,171.47317s10.83239,25.72691-13.54048,37.91334,64.9943,35.20525,74.47264,5.41619c0,0-24.37286-9.47833-13.54048-37.91334Z"
      transform="translate(-112.54273 -84)"
      fill="#ffb9b9"
    />
    <polygon
      points="528.756 618.937 527.402 647.372 550.42 651.434 550.42 622.999 528.756 618.937"
      fill="#ffb9b9"
    />
    <polygon
      points="442.097 618.937 443.451 647.372 420.432 651.434 420.432 622.999 442.097 618.937"
      fill="#ffb9b9"
    />
    <circle cx="526.04763" cy="65.13138" r="37.91334" fill="#ffb9b9" />
    <polygon
      points="578.856 340.003 577.501 350.835 584.272 376.562 593.75 353.544 578.856 340.003"
      fill="#ffb9b9"
    />
    <path
      d="M541.09891,428.06526l-8.12428,96.1374s-9.47834,17.60263-4.06215,32.49715c0,0-8.12429,20.31072-5.41619,27.081s4.06214,13.54048,1.354,16.24858-4.06215,78.53478,0,85.305,6.77024,23.01882,6.77024,23.01882,23.01881,5.41619,28.435-9.47834,2.70809-16.24857,8.12428-20.31072,5.41619-60.93216,5.41619-60.93216-6.77024-6.77024-2.70809-9.47833,6.77024,0,2.70809-6.77024-10.83238-6.77024-4.06214-9.47834a64.56047,64.56047,0,0,0,10.83238-5.41619l39.2674-102.90765s14.89452,115.09408,17.60262,120.51027,6.77024-1.354,2.70809,5.4162-5.41619,2.70809-4.06214,6.77024-8.12429,62.2862-2.70809,74.47263a183.49249,183.49249,0,0,1,8.12428,21.66477s21.66477,9.47834,24.37287,2.7081,1.354-18.95667,5.41619-20.31072,8.12429-4.06215,8.12429-14.89453,8.12428-63.64025,8.12428-63.64025-9.47833-4.06215-6.77024-10.83239,2.7081-5.41619,1.35405-10.83238-4.06214-6.77024-1.35405-10.83239,2.7081-16.24857,2.7081-16.24857l16.24858-106.96979-12.54764-36.5593Z"
      transform="translate(-112.54273 -84)"
      fill="#2f2e41"
    />
    <path
      d="M642.65251,724.60176s-4.06214-4.06214-5.41619,0-1.35405,41.97549-1.35405,41.97549,10.83238,8.12429,10.83238,12.18643,8.12429,17.60262,27.081,14.89453q.28436-.04062.56263-.08487a17.927,17.927,0,0,0,13.32944-25.3754l-19.30826-40.88808S654.83894,713.76938,642.65251,724.60176Z"
      transform="translate(-112.54273 -84)"
      fill="#2f2e41"
    />
    <path
      d="M553.28535,724.60176s4.06214-4.06214,5.41619,0,1.35405,41.97549,1.35405,41.97549-10.83239,8.12429-10.83239,12.18643-8.12429,17.60262-27.081,14.89453q-.28434-.04062-.56263-.08487a17.927,17.927,0,0,1-13.32944-25.3754l19.30826-40.88808S541.09891,713.76938,553.28535,724.60176Z"
      transform="translate(-112.54273 -84)"
      fill="#2f2e41"
    />
    <path
      d="M609.69425,108.06512l-6.7739-2.71189S617.08388,89.76,636.78982,91.116l-5.54241-6.10178s13.54778-5.42368,25.8639,8.81356c6.47434,7.48423,13.96524,16.28152,18.635,26.19158h7.25436L679.973,126.686l10.597,6.66666-10.87674-1.19751a36.88965,36.88965,0,0,1-1.02876,17.26591l.29154,5.26932s-12.60766-19.50667-12.60766-22.21856v6.77968s-6.77389-6.10168-6.77389-10.16946l-3.69482,4.74578-1.84741-7.45767-22.78482,7.45767,3.69481-6.10168L620.7787,129.76l5.54241-7.45767s-16.01094,8.81357-16.62686,16.27124c-.61574,7.45757-8.62112,16.94913-8.62112,16.94913l-3.69482-6.77967S591.8359,118.23458,609.69425,108.06512Z"
      transform="translate(-112.54273 -84)"
      fill="#2f2e41"
    />
    <path
      d="M696.81443,233.08235h15.08586a4.881,4.881,0,0,1,4.76412,3.80805c2.32794,10.30815,8.3268,39.52814,7.231,62.5403-1.35405,28.435,1.35405,48.74573,0,52.80787s0,5.41619,0,9.47834,4.06214,1.35405,0,6.77024-4.06215,4.06214-4.06215,8.12429-13.54047,66.34835-13.54047,66.34835-9.30891-15.29975-19.549-12.389l1.94635-55.31336,2.7081-23.01882-4.06215-40.62144Z"
      transform="translate(-112.54273 -84)"
      fill="#3f3d56"
    />
    <path
      d="M631.1431,218.86485s-23.01881-.83665-30.46608-10.57369c0,0-48.0687,15.98988-53.48489,29.53036s18.95667,74.47264,18.95667,74.47264S524.17331,436.86657,544.484,436.86657s66.34835,16.24858,93.42931,6.77024a348.04666,348.04666,0,0,1,51.45383-13.54048s2.70809-36.55929,0-43.32953-9.47834-2.7081-5.4162-12.18644,2.7081-10.83238,1.35405-14.89452,2.7081-9.47834,2.7081-14.89453-1.354-17.60263-1.354-17.60263,36.55929-85.305,28.435-93.4293-49.479-23.77833-49.479-23.77833S654.16192,224.281,631.1431,218.86485Z"
      transform="translate(-112.54273 -84)"
      fill="#3f3d56"
    />
    <circle cx="823.26116" cy="518.06042" r="29.78906" fill="#60a5fa" />
    <ellipse
      cx="802.95044"
      cy="518.06042"
      rx="6.77024"
      ry="14.89453"
      fill="#fff"
    />
    <circle cx="727.12376" cy="327.13966" r="29.78906" fill="#3f3d56" />
    <ellipse
      cx="827.75152"
      cy="394.69098"
      rx="14.89453"
      ry="6.77024"
      transform="translate(-186.68666 476.63723) rotate(-35.91853)"
      fill="#fff"
    />
    <circle cx="802.95044" cy="163.29986" r="18.56442" fill="#3f3d56" />
    <ellipse
      cx="908.06782"
      cy="237.04911"
      rx="9.28221"
      ry="4.21919"
      transform="translate(-78.93653 493.77822) rotate(-35.91853)"
      fill="#fff"
    />
    <circle cx="663.4835" cy="641.27879" r="18.56442" fill="#3f3d56" />
    <ellipse
      cx="768.60088"
      cy="715.02804"
      rx="9.28221"
      ry="4.21919"
      transform="translate(-385.85477 502.8489) rotate(-35.91853)"
      fill="#fff"
    />
    <circle cx="820.55307" cy="382.65563" r="29.78906" fill="#3f3d56" />
    <ellipse
      cx="800.24235"
      cy="382.65563"
      rx="6.77024"
      ry="14.89453"
      fill="#fff"
    />
    <circle cx="724.41566" cy="191.73486" r="29.78906" fill="#60a5fa" />
    <ellipse
      cx="825.04343"
      cy="259.28619"
      rx="14.89453"
      ry="6.77024"
      transform="translate(-107.76849 449.3016) rotate(-35.91853)"
      fill="#fff"
    />
    <circle cx="800.24235" cy="27.89506" r="18.56442" fill="#60a5fa" />
    <ellipse
      cx="905.35972"
      cy="101.64431"
      rx="9.28221"
      ry="4.21919"
      transform="translate(-0.01836 466.4426) rotate(-35.91853)"
      fill="#fff"
    />
    <circle cx="660.77541" cy="505.87399" r="18.56442" fill="#3f3d56" />
    <ellipse
      cx="765.89278"
      cy="579.62324"
      rx="9.28221"
      ry="4.21919"
      transform="translate(-306.9366 475.51328) rotate(-35.91853)"
      fill="#fff"
    />
    <rect
      x="67.7024"
      y="383.9221"
      width="375.07129"
      height="227.65521"
      fill="#f2f2f2"
    />
    <path
      d="M524.85034,437.54359s-12.18643,63.64026,8.12429,58.22407S543.807,437.54359,543.807,437.54359Z"
      transform="translate(-112.54273 -84)"
      fill="#ffb9b9"
    />
    <path
      d="M555.99344,233.08235l-4.8089,2.40445a13.562,13.562,0,0,0-7.26457,9.63085L524.85034,346.82238s-5.41619,12.18643-5.41619,13.54048,2.70809,1.354,0,5.41619-6.77024,2.7081-4.06215,5.41619a8.38725,8.38725,0,0,1,2.7081,5.4162l2.70809,66.34835s23.01882-6.77024,29.78906,0c0,0-6.77024-20.31072-2.7081-27.081s4.06215-16.24858,4.06215-16.24858-2.7081,0,4.06214-6.77024,8.12429-9.47833,6.77024-10.83238-2.70809-13.54048-2.70809-14.89453,13.54047-92.07526,13.54047-92.07526Z"
      transform="translate(-112.54273 -84)"
      fill="#3f3d56"
    />
    <circle cx="259.90311" cy="492.15162" r="82.10516" fill="#60a5fa" />
    <path
      d="M443.93848,627.70117c-15.76416.001-50.57569-16.19043-81.53565-35.15332-38.41845-23.53125-75.73047-54.28808-69.20752-64.93848,1.84375-3.00976,6.41358-3.79687,13.97315-2.40917l-.542,2.95117c-5.854-1.07715-9.81542-.70117-10.87255,1.02441-1.542,2.51758,1.85791,9.82617,16.02343,22.78809,12.90918,11.81347,31.44483,25.31738,52.19287,38.02539,20.74756,12.708,41.19971,23.084,57.5879,29.21679,17.98339,6.73047,26.03857,6.43653,27.57959,3.9209,1.05859-1.72851-.41211-5.43261-4.03565-10.16406l2.38184-1.82422c4.6792,6.11035,6.05713,10.54395,4.2124,13.55469C450.42969,626.76172,447.73682,627.70117,443.93848,627.70117Z"
      transform="translate(-112.54273 -84)"
      fill="#3f3d56"
    />
    <circle cx="249.7686" cy="449.94874" r="16.79424" fill="#fff" />
    <circle cx="254.63291" cy="542.01412" r="11.19616" fill="#fff" />
    <circle cx="293.18741" cy="503.63057" r="11.19616" fill="#fff" />
    <path
      d="M191.63319,455.1069a26.37141,26.37141,0,1,0-34.2271,40.12868l40.12868,34.2271,34.2271-40.12868a26.37141,26.37141,0,0,0-40.12868-34.2271Z"
      transform="translate(-112.54273 -84)"
      fill="#ff6584"
    />
  </svg>
)

export default Inspiration

import React from 'react'


const PartnerImage = () => (
  <svg id="e7c617c0-9ccb-498f-9bd6-fe88e17bb2e8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 994.9952 662.08019"><title>love</title>
    <path
      d="M1097.44,499.83007A287.1373,287.1373,0,0,1,933.75,753.84008a282.14734,282.14734,0,0,1-121.25,27.2q-9.045,0-17.96-.56a3110.25767,3110.25767,0,0,0-389.08,0q-8.91.555-17.96.56a282.05767,282.05767,0,0,1-138.92-36.35,287.20949,287.20949,0,0,1-146.02-244.86c-3.16-160.68,124.03-292.05,283.61-292.79,84.99-.39,161.37,36.69,213.83,95.77,52.46-59.08,128.84-96.16,213.83-95.77C973.41,207.78008,1100.6,339.15008,1097.44,499.83007Z"
      transform="translate(-102.5024 -118.95991)" fill="#f2f2f2"/>
    <path
      d="M933.75,753.84008a282.14734,282.14734,0,0,1-121.25,27.2q-9.045,0-17.96-.56a3110.25767,3110.25767,0,0,0-389.08,0q-8.91.555-17.96.56a282.05767,282.05767,0,0,1-138.92-36.35c64.13-27.78,189.39-46.65,333.42-46.65C741.34006,698.04009,877.71005,721.13006,933.75,753.84008Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path
      d="M736.37745,356.11721a22.982,22.982,0,0,0-23.87427-3.78181c-11.87754,5.18011-17.05037,19.7316-16.21984,32.66294s6.21884,25.06152,9.7085,37.54077c4.68719,16.7617,5.9044,34.92858.41078,51.44356s-18.41986,31.06888-35.18671,35.73763c-15.5625,4.33341-32.071-.01139-47.63369-4.344"
      transform="translate(-102.5024 -118.95991)" fill="none" stroke="#3f3d56" stroke-miterlimit="10"/>
    <path
      d="M764.55327,330.27885a13.56878,13.56878,0,1,0-27.09253-1.5626l-1.56259,27.09252,27.09252,1.5626a13.56877,13.56877,0,1,0,1.5626-27.09252Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M735.42067,400.82168a13.56877,13.56877,0,1,0-27.09252-1.56259l-1.5626,27.09252,27.09253,1.5626a13.56878,13.56878,0,0,0,1.56259-27.09253Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M696.38333,325.25362a13.56878,13.56878,0,1,0-17.42812,20.80162l20.80162,17.42812L717.185,342.68174a13.56878,13.56878,0,1,0-20.80162-17.42812Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M858.424,343.76558a22.982,22.982,0,0,0-22.00429-10.00471c-12.82825,1.829-21.69035,14.47687-24.33443,27.16222s-.68165,25.81258-.64225,38.7705c.05292,17.40465-3.61307,35.23938-13.3074,49.69429s-26.03034,25.03972-43.435,25.07349c-16.15453.03136-30.90917-8.55392-44.75552-16.8755"
      transform="translate(-102.5024 -118.95991)" fill="none" stroke="#3f3d56" stroke-miterlimit="10"/>
    <path
      d="M892.4645,326.36614a13.56877,13.56877,0,0,0-25.69741-8.72294l-8.723,25.69741,25.69741,8.72294a13.56878,13.56878,0,1,0,8.723-25.69741Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M845.59355,386.6a13.56878,13.56878,0,0,0-25.69741-8.723l-8.72294,25.69741,25.69741,8.723A13.56877,13.56877,0,1,0,845.59355,386.6Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M828.09619,303.36363a13.56878,13.56878,0,1,0-22.33948,15.40761l15.4076,22.33948,22.33949-15.4076a13.56878,13.56878,0,0,0-15.40761-22.33949Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M799.94623,162.865a22.982,22.982,0,0,1,22.28762,9.35655c7.36973,10.65816,4.10487,25.75269-3.41049,36.30866S800.3398,226.561,790.8019,235.33246c-12.811,11.78146-23.412,26.5847-27.43806,43.51737s-.70079,36.112,11.09475,48.91c10.94829,11.87877,27.27047,16.878,42.78223,21.38973"
      transform="translate(-102.5024 -118.95991)" fill="none" stroke="#3f3d56" stroke-miterlimit="10"/>
    <path
      d="M789.59894,126.06238a13.56878,13.56878,0,1,1,23.855,12.9378l-12.93781,23.855-23.855-12.93781a13.56877,13.56877,0,1,1,12.9378-23.855Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M777.2194,201.37338a13.56878,13.56878,0,1,1,23.855,12.9378l-12.93781,23.855-23.855-12.93781a13.56877,13.56877,0,1,1,12.9378-23.855Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <path
      d="M850.19836,157.68679a13.56878,13.56878,0,1,1,3.86257,26.86126l-26.86126,3.86257-3.86256-26.86126a13.56877,13.56877,0,1,1,26.86125-3.86257Z"
      transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    <rect x="440.49763" y="331.08019" width="25" height="13" fill="#fbbebe"/>
    <rect x="440.49763" y="331.08019" width="25" height="13" opacity="0.1"/>
    <circle cx="394.49763" cy="112.08019" r="32" fill="#2f2e41"/>
    <path d="M569.5,443.54009s-10-3-12-2,7,9,7,9Z" transform="translate(-102.5024 -118.95991)" fill="#fbbebe"/>
    <path d="M569.5,443.54009s-10-3-12-2,7,9,7,9Z" transform="translate(-102.5024 -118.95991)" opacity="0.1"/>
    <path d="M500.5,699.54009s23,3,27,3,15,4,9,9-44,12-49,9-12-12-7-13S500.5,699.54009,500.5,699.54009Z"
          transform="translate(-102.5024 -118.95991)" fill="#3f3d56"/>
    <polygon
      points="356.998 617.58 348.998 631.58 358.998 636.58 377.998 640.58 387.998 627.58 383.998 612.58 356.998 617.58"
      fill="#fbbebe"/>
    <path d="M512.5,633.54009s-13,55-11,58,6,11,2,14-20,4-20,4l5-29,7-35Z" transform="translate(-102.5024 -118.95991)"
          fill="#2f2e41"/>
    <path
      d="M476.5,517.54009l13,59s2,29-3,40-32,127-32,127,28,1,34-2,16.11941-72.26515,16.11941-72.26515S537.5,603.54009,536.5,590.54009s4.9685-79.037-3.51575-84.01852S476.5,517.54009,476.5,517.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path
      d="M478.5,754.54009l12-12s32,16,37,17,16,9,5,12-52,1-62,0-30-2-27-8,8-27,8-27,2.76378-3.82086,4.38189,1.08957S458.5,749.54009,462.5,749.54009,478.5,754.54009,478.5,754.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#3f3d56"/>
    <path d="M632.5,500.54009l28-2,19,10-2,12-8,2s-22-9-35-6S632.5,500.54009,632.5,500.54009Z"
          transform="translate(-102.5024 -118.95991)" fill="#a0616a"/>
    <path
      d="M665.5,504.54009s11,12.03168,0,16.51584c0,0,2,3.48416,10,4.48416s19,8,22,8,16-2,8-13-40-35-40-35-9-4-12,4-4.58292,9.82735-4.58292,9.82735S663.5,502.54009,665.5,504.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path d="M581.5,600.54009s9,34,9,36,12-8,12-8l2-18s-8-8-8-13S581.5,600.54009,581.5,600.54009Z"
          transform="translate(-102.5024 -118.95991)" fill="#a0616a"/>
    <path
      d="M599.5,624.54009s-7,9-12,6l3,34s2,17,9,10,14-42,14-42,10-21,2-24a83.28173,83.28173,0,0,0-14-4S601.5,625.54009,599.5,624.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path d="M534.5,510.54009l30,62s10,35,14,36,19-6,21-7-17-40-17-47-23-46-23-46Z"
          transform="translate(-102.5024 -118.95991)" fill="#ff6584"/>
    <path d="M534.5,510.54009l30,62s10,35,14,36,19-6,21-7-17-40-17-47-23-46-23-46Z"
          transform="translate(-102.5024 -118.95991)" opacity="0.1"/>
    <path
      d="M555.5,402.54009s1,21-3,35,5,60,5,60,15,1,19,0,58-2,61,0,2,37,0,36-54-12-79-8-28-2-29-4-18-56-18-56l-6-26-8-28,5-27Z"
      transform="translate(-102.5024 -118.95991)" fill="#ff6584"/>
    <circle cx="392.49763" cy="126.08019" r="27" fill="#a0616a"/>
    <circle cx="341.99763" cy="189.58019" r="27" fill="#fbbebe"/>
    <path
      d="M502.5,263.54009s18,12,15,19-11,15-11,15l-1,11,15,5,38,2s-2-34-14-43-26-15-28-23S502.5,263.54009,502.5,263.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#a0616a"/>
    <path
      d="M552.11621,288.20572c-6.54432-12.22565-24.95678-10.74009-28.77273,2.59157a12.062,12.062,0,0,0,1.15655,9.7428c7,12,12,21,12,21l22-2S558.9326,300.93961,552.11621,288.20572Z"
      transform="translate(-102.5024 -118.95991)" fill="#a0616a"/>
    <path
      d="M509.78638,293.95391S483.5,310.54009,485.5,321.54009s4,30,4,37,7,29,17,33,10-7,24,6,29,19,28,21,4-18-4-26-17-31-9-41,15-36,15-36-1-6-7-5-43-3-45-8A8.44311,8.44311,0,0,1,509.78638,293.95391Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path
      d="M506.05515,302.43379S533.5,272.54009,537.5,273.54009s15.51919,12.83425,15.51919,12.83425L537.5,275.54009l-31.44488,28.57874Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path
      d="M467.5,313.54009s3.75627,40.44582,8.87813,44.22291S437.5,360.54009,437.5,360.54009s10-31,4-35S467.5,313.54009,467.5,313.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#fbbebe"/>
    <path
      d="M477.5,346.54009s-34-9-38-4-12,48-12,48,4,29,11,50,16,95,23,95,19-11,22-11,21-9,32-7a38.93341,38.93341,0,0,0,19-1s-5-42-17-53-18-87-18-87S482.5,344.54009,477.5,346.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#3f3d56"/>
    <path d="M529.5,441.54009s11.37059,15.43307,22.6853,16.21654l6.3147-.21654s-10,26-5,27,20-19,15-30-20-28-20-28Z"
          transform="translate(-102.5024 -118.95991)" fill="#fbbebe"/>
    <path d="M569.5,441.54009s-17,16-10,23,16,0,16,0S582.5,454.54009,569.5,441.54009Z"
          transform="translate(-102.5024 -118.95991)" fill="#fbbebe"/>
    <path d="M569.5,441.54009s-17,16-10,23,16,0,16,0S582.5,454.54009,569.5,441.54009Z"
          transform="translate(-102.5024 -118.95991)" opacity="0.1"/>
    <circle cx="401.49763" cy="106.08019" r="32" fill="#2f2e41"/>
    <circle cx="416.49763" cy="79.08019" r="19" fill="#2f2e41"/>
    <path
      d="M504,192.04009a19.00069,19.00069,0,0,1,17-18.89319,19.19334,19.19334,0,0,0-2-.10681,19,19,0,1,0,2,37.89319A19.00068,19.00068,0,0,1,504,192.04009Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <ellipse cx="390.99763" cy="119.08019" rx="25.5" ry="19" fill="#2f2e41"/>
    <path
      d="M445.626,298.51612c-.35032,1.87684-1.1833,3.763-.47965,5.6957.52139,1.43211,1.7908,2.56741,2.55311,3.92377,1.53462,2.73052.51782,6.22586-2.12233,7.29572a7.14659,7.14659,0,0,0-4.9115-.83115,3.19888,3.19888,0,0,0-2.47848,3.4676c.352,2.30917,3.04352,3.78843,4.41254,5.84839a15.13121,15.13121,0,0,1,1.67206,4.95876c.67355,2.69657,1.859,5.374,1.996,8.07161s-1.17541,5.5488-3.85209,5.935c-2.25074.32471-4.67047-1.20085-6.29822-3.05284s-2.72607-4.04868-4.2172-5.99843c-4.84525-6.33544-13.12635-9.28788-18.73431-15.03718-7.18577-7.36687-8.70777-18.27964-6.13684-26.97819,1.07964-3.65289,2.86588-7.14472,5.90146-9.37355,3.18561-2.339,7.43992-3.08705,11.00748-4.906,3.81511-1.94511,6.74181-5.05667,10.116-7.59641s7.55424-4.57614,12.15934-3.89124c3.212.4777,6.25874,2.22624,9.44649,3.051a15.28308,15.28308,0,0,0,10.41661-.85878l-2.96158,3.17831a15.08272,15.08272,0,0,0,6.71233,1.01723,8.13783,8.13783,0,0,1-4.50531,4.06928,8.0857,8.0857,0,0,0,5.61584,1.69277c-2.19839,2.5114-4.55962,5.12475-7.9464,5.99975-1.65893.4286-3.46576.40787-5.17823.68773-3.65018.59653-6.72335,2.52351-9.70364,4.41464-1.56494.993-3.53334,2.11918-3.5222,4.18051C444.59662,295.1967,445.98406,296.598,445.626,298.51612Z"
      transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <path
      d="M457.5,356.54009s-9,33,7,40,49,33,55,42,13,12,13,12,24-20,19-26-25-24-25-24S475.5,345.54009,457.5,356.54009Z"
      transform="translate(-102.5024 -118.95991)" fill="#3f3d56"/>
    <path d="M536.5,357.54009s-36.739-10.50232-47.8695-12.25116S448.5,346.54009,454.5,351.54009s41,9,41,9l42,16Z"
          transform="translate(-102.5024 -118.95991)" fill="#a0616a"/>
    <path d="M536.5,316.54009s3,20,3,27,5,13,1,13-7-4-7,0-6,28,0,28,21-4,24-4,13-2,12-13-5-23-3-25,3-12,1-14-7-15-7-15Z"
          transform="translate(-102.5024 -118.95991)" fill="#2f2e41"/>
    <g opacity="0.3">
      <path
        d="M747.86978,520.08854a7.00852,7.00852,0,1,0-1.6712,13.91706l13.91706,1.67121,1.6712-13.91707a7.00852,7.00852,0,1,0-13.91706-1.6712Z"
        transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    </g>
    <g opacity="0.3">
      <path
        d="M915.59928,453.21218a7.00853,7.00853,0,0,0-6.88292,12.21078l12.21078,6.88291,6.88292-12.21077a7.00853,7.00853,0,1,0-12.21078-6.88292Z"
        transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    </g>
    <g opacity="0.3">
      <path
        d="M840.86978,548.08854a7.00852,7.00852,0,1,0-1.6712,13.91706l13.91706,1.67121,1.6712-13.91707a7.00852,7.00852,0,1,0-13.91706-1.6712Z"
        transform="translate(-102.5024 -118.95991)" fill="#60a5fa"/>
    </g>
  </svg>
)

export default PartnerImage
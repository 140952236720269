import React, {useEffect} from 'react'
import OwnerInfoBox from '../input-box/owner-input-box'
import {SaJooAnalysis} from '../../types/saJoo/SaJooAnalysis'
import SEO from '../seo'
import {CircularProgress} from '@material-ui/core'
import Inspiration from '../common/Inspiration'
import {SAJOO_PRICE} from '../../constants'
import {ProductType} from '../../store/payment/types'
import SaJooContents from './sajoo-contents'
import {Element, scroller} from "react-scroll/modules";
import {Container} from "../../styles/common";
import {changeMoveToAfterLoad} from "../../store/common/actions";
import LoadingBox from "../loading-box/loading-box";
import {type} from "os";

interface Props {
  saJooAnalysis?: SaJooAnalysis
  fetchSaJooAnalysis: () => void
  fetchSampleSaJooAnalysis: () => void
  openPayBox: () => void
  isLoading: boolean
  changePrice: (price: number) => void
  changeProduct: (product: ProductType) => void
  code?: string
  moveToAfterLoad: boolean
  changeMoveToAfterLoad: (val: boolean) => void
}


// 메인 페이지에 사용될 오늘의 운세 컨테이너
const SaJooContainer: React.FC<Props> = (props) => {
  const {
    saJooAnalysis,
    fetchSaJooAnalysis,
    fetchSampleSaJooAnalysis,
    isLoading,
    openPayBox,
    changePrice,
    changeProduct,
    code,
    moveToAfterLoad,
    changeMoveToAfterLoad,
  } = props

  useEffect(() => {
    if (process.env.NODE_ENV === 'production'){
      gtag('event', 'conversion', {'send_to': 'AW-835557888/Sya4CN3agfEBEIC0to4D'});
    }
    if (moveToAfterLoad) {
      scroller.scrollTo('after-load', {
        duration: 800,
        delay: 0,
        offset: -70,
        smooth: 'easeInOutQuart',
      })
      changeMoveToAfterLoad(false)
    }
  }, [])

  return (
    <div>
      <SEO
        title={'온라인 사주 풀이'}
        description={
          '전문 명리학자가 사용하는 에잇코즈의 정통 사주 명리 분석 프로그램을 기반으로 정밀한 정밀한 사주 풀이를 제공합니다.'
        }
      />
      <section className="pt-10 sm:pt-20 mb-40 mt-10">
        <div
          className={
            'container max-w-screen-xl mx-auto px-8 lg:flex lg:items-center'
          }
        >
          <div className="text-center  lg:w-1/2">
            <h1 className="text-5xl lg:text-6xl xl:text-6xl font-bold leading-none">
              사주 풀이
            </h1>
            <h2 className="text-xl sm:text-2xl  font-semibold text-gray-500">
              쉽고 빠르게 사주를 풀어보세요.
            </h2>

            <OwnerInfoBox/>
            <button
              className={
                'block mx-auto mb-2 max-w-full  w-72  py-3    bg-black hover:bg-gray-800 rounded text-white'
              }
              // style={{width: 300}}
              onClick={() => {
                if (!isLoading) {

                  // 결제 없이 사주 풀이
                  // fetchSaJooAnalysis()
                  // 결제 후 사주 풀이
                  changePrice(SAJOO_PRICE)
                  changeProduct(ProductType.SAJOO)
                  openPayBox()
                }
              }}
            >

              {`${SAJOO_PRICE.toLocaleString()}원 결제`}

            </button>
            <button
              className={
                'block mx-auto mb-2 max-w-full w-72  py-3    bg-blue-400 hover:bg-blue-500 rounded text-white'}
              onClick={
                () => {
                  if (!isLoading) {
                    fetchSampleSaJooAnalysis()
                  }
                }
              }
            >
              랜덤 샘플 보기
            </button>
          </div>
          <div className="lg:w-1/2">
            <div className="mx-auto max-w-2xl">
              <Inspiration/>
            </div>
          </div>
        </div>
      </section>
      <Element name={'after-load'}/>
      {saJooAnalysis && code && (
        <SaJooContents code={code} saJooAnalysis={saJooAnalysis}/>
      )}
    </div>
  )
}

export default SaJooContainer

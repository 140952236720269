import React from 'react'


const EgoImage = () => (
  <svg id="46e57871-9956-42ae-80ad-44debb9ce8bb" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink"  viewBox="0 0 778 761.81">
    <defs>
      <linearGradient id="4e52bdea-3ee3-4982-ab0f-41ec51f7690b" x1="593" y1="777.92" x2="593" y2="344.92"
                      gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="gray" stop-opacity="0.25"/>
        <stop offset="0.54" stop-color="gray" stop-opacity="0.12"/>
        <stop offset="1" stop-color="gray" stop-opacity="0.1"/>
      </linearGradient>
    </defs>
    <title>Meditation</title>
    <path
      d="M955.66,704.53h-745c-9.12,0-16.51,4.59-16.51,10.25v106c0,5.66,7.39,10.25,16.51,10.25h745c9.12,0,16.52-4.59,16.52-10.25v-106C972.18,709.12,964.78,704.53,955.66,704.53Z"
      transform="translate(-194.18 -69.27)" fill="#60a5fa"/>
    <path
      d="M955.66,704.53h-745c-9.12,0-16.51,4.59-16.51,10.25v106c0,5.66,7.39,10.25,16.51,10.25h745c9.12,0,16.52-4.59,16.52-10.25v-106C972.18,709.12,964.78,704.53,955.66,704.53Z"
      transform="translate(-194.18 -69.27)" opacity="0.2"/>
    <rect y="635.26" width="778" height="119.12" rx="14.09" ry="14.09" fill="#60a5fa"/>
    <rect y="651.69" width="778" height="10.27" fill="#fff" opacity="0.05"/>
    <rect y="675.65" width="778" height="10.27" fill="#fff" opacity="0.05"/>
    <rect y="699.61" width="778" height="10.27" fill="#fff" opacity="0.05"/>
    <rect y="723.57" width="778" height="10.27" fill="#fff" opacity="0.05"/>
    <g opacity="0.5">
      <path
        d="M854.32,278.35a10.9,10.9,0,0,0-14.21,1.32,11.13,11.13,0,0,0,.44,15.51l13.58,13.5a2.27,2.27,0,0,0,3.21,0l13.5-13.58a11.13,11.13,0,0,0,.36-15.51A10.88,10.88,0,0,0,857,278.34,2.3,2.3,0,0,1,854.32,278.35Z"
        transform="translate(-194.18 -69.27)" fill="#f55f44"/>
      <path
        d="M855.83,308.15a2,2,0,0,0,0,1.89,9.77,9.77,0,0,1,.53.88c.35.87-.26,1.82-.88,2.53-.39.45-.82,1.05-.57,1.6a2.07,2.07,0,0,0,.46.52,3.34,3.34,0,0,1-1.27,5.58"
        transform="translate(-194.18 -69.27)" fill="none" stroke="#f55f44" stroke-miterlimit="10"/>
    </g>
    <g opacity="0.5">
      <path
        d="M274.32,352.35a10.9,10.9,0,0,0-14.21,1.32,11.13,11.13,0,0,0,.44,15.51l13.58,13.5a2.27,2.27,0,0,0,3.21,0l13.5-13.58a11.13,11.13,0,0,0,.36-15.51A10.88,10.88,0,0,0,277,352.34,2.3,2.3,0,0,1,274.32,352.35Z"
        transform="translate(-194.18 -69.27)" fill="#f55f44"/>
      <path
        d="M275.83,382.15a2,2,0,0,0,0,1.89,9.77,9.77,0,0,1,.53.88c.35.87-.26,1.82-.88,2.53-.39.45-.82,1.05-.57,1.6a2.07,2.07,0,0,0,.46.52,3.34,3.34,0,0,1-1.27,5.58"
        transform="translate(-194.18 -69.27)" fill="none" stroke="#f55f44" stroke-miterlimit="10"/>
    </g>
    <g opacity="0.5">
      <path
        d="M654.32,71.35a10.9,10.9,0,0,0-14.21,1.32,11.13,11.13,0,0,0,.44,15.51l13.58,13.5a2.27,2.27,0,0,0,3.21,0l13.5-13.58a11.13,11.13,0,0,0,.36-15.51A10.88,10.88,0,0,0,657,71.34,2.3,2.3,0,0,1,654.32,71.35Z"
        transform="translate(-194.18 -69.27)" fill="#f55f44"/>
      <path
        d="M655.83,101.15a2,2,0,0,0,0,1.89,9.77,9.77,0,0,1,.53.88c.35.87-.26,1.82-.88,2.53-.39.45-.82,1-.57,1.6a2.07,2.07,0,0,0,.46.52,3.34,3.34,0,0,1-1.27,5.58"
        transform="translate(-194.18 -69.27)" fill="none" stroke="#f55f44" stroke-miterlimit="10"/>
    </g>
    <g opacity="0.5">
      <rect x="552" y="141.18" width="3" height="17" fill="#47e6b1"/>
      <rect x="746.18" y="210.44" width="3" height="17" transform="translate(772.44 -598) rotate(90)" fill="#47e6b1"/>
    </g>
    <g opacity="0.5">
      <rect x="206" y="402.18" width="3" height="17" fill="#47e6b1"/>
      <rect x="400.18" y="471.44" width="3" height="17" transform="translate(687.44 9) rotate(90)" fill="#47e6b1"/>
    </g>
    <g opacity="0.5">
      <rect x="128" y="120.18" width="3" height="17" fill="#47e6b1"/>
      <rect x="322.18" y="189.44" width="3" height="17" transform="translate(327.44 -195) rotate(90)" fill="#47e6b1"/>
    </g>
    <g opacity="0.5">
      <rect x="661" y="383.18" width="3" height="17" fill="#47e6b1"/>
      <rect x="855.18" y="452.44" width="3" height="17" transform="translate(1123.44 -465) rotate(90)" fill="#47e6b1"/>
    </g>
    <path
      d="M742.27,331.06a3.68,3.68,0,0,1-2-4.44,1.19,1.19,0,0,0,.08-.41h0A1.84,1.84,0,0,0,737,325h0a2.08,2.08,0,0,0-.2.36,3.68,3.68,0,0,1-4.44,2,1.86,1.86,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.82,1.82,0,0,0,.36.2,3.67,3.67,0,0,1,2,4.44,2,2,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.58,1.58,0,0,0,.2-.36,3.69,3.69,0,0,1,4.45-2.05,1.77,1.77,0,0,0,.4.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.58,1.58,0,0,0,742.27,331.06Z"
      transform="translate(-194.18 -69.27)" fill="#4d8af0" opacity="0.5"/>
    <path
      d="M377.27,293.06a3.68,3.68,0,0,1-2-4.44,1.19,1.19,0,0,0,.08-.41h0A1.84,1.84,0,0,0,372,287h0a2.08,2.08,0,0,0-.2.36,3.68,3.68,0,0,1-4.44,2,1.86,1.86,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.82,1.82,0,0,0,.36.2,3.67,3.67,0,0,1,2.05,4.44,2,2,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.58,1.58,0,0,0,.2-.36,3.69,3.69,0,0,1,4.45-2.05,1.77,1.77,0,0,0,.4.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.58,1.58,0,0,0,377.27,293.06Z"
      transform="translate(-194.18 -69.27)" fill="#4d8af0" opacity="0.5"/>
    <path
      d="M521.27,98.06a3.68,3.68,0,0,1-2-4.44,1.19,1.19,0,0,0,.08-.41h0A1.84,1.84,0,0,0,516,92h0a2.08,2.08,0,0,0-.2.36,3.68,3.68,0,0,1-4.44,2.05,1.86,1.86,0,0,0-.41-.08h0a1.84,1.84,0,0,0-1.22,3.31h0a1.82,1.82,0,0,0,.36.2,3.67,3.67,0,0,1,2.05,4.44,2,2,0,0,0-.08.41h0a1.84,1.84,0,0,0,3.31,1.22h0a1.58,1.58,0,0,0,.2-.36,3.69,3.69,0,0,1,4.45-2.05,1.77,1.77,0,0,0,.4.08h0a1.84,1.84,0,0,0,1.22-3.31h0A1.58,1.58,0,0,0,521.27,98.06Z"
      transform="translate(-194.18 -69.27)" fill="#4d8af0" opacity="0.5"/>
    <path
      d="M759.66,682.08,711,644.71s-3.92-15.56-5.49-26.46-3.93-57.6-3.93-57.6a67.73,67.73,0,0,0,2.21-12.36c.48-.1,1-.22,1.45-.35a24.25,24.25,0,0,0,16.39-14.13c5-12.44-1.46-27.14-9.64-37s-18.48-17.78-23.86-30c-6.64-15.05-4.37-33.26-7.76-49.74a15.05,15.05,0,0,0-2.5-6.43c-2.11-2.61-5.4-3.17-8.33-4.21-9.28-3.3-16.24-12.31-21.67-21.72-8.79-15.25-17.89-38.45-36-39.77-6.73-.49-13.75,1.78-20.56,1.76-10.55,0-21,.13-31.46,1.58-3.74.51-7.61,1.21-10.73,3.67-10.71,8.48-5.86,30.87-16.18,40-3.07,2.7-7,3.68-10.47,5.54-13.08,7-18.89,25.67-18.12,42.46.37,7.88,2,16.07,6.65,21.82,3.69,4.6,8.93,7.24,12.37,12.1,5.53,7.82,4.78,18.21,4.18,28.77-7.4,3.1-13.34,6.66-15.21,10.49C506,526,506.82,570,506.82,570s2.35,72-15.71,81l-67.52,46.7s-47.9-41.64-59.68-17.12c-8.05,16.77,21.35,26.26,40.75,30.58-12.23,8.68-19,24.72-13.27,35.58,9,17.13,34.16,28.8,34.16,28.8s95.95,4.63,140.3-5.31h.73c18.83,3.41,45.85,7,67.52,4.84.42,1,.71,1.64.71,1.64s139,11.68,164.11-35.8c12.88-24.36-.56-35.6-16.72-40.78,18.09-1.44,38.69-6.5,41.06-21.89C827.58,650.16,759.66,682.08,759.66,682.08Zm-14,14.89c-25.44,9.26-46.08,9.78-57.08,9.09-5.4-11.78-27.92-62.79-28.51-96.94.15-7.62,1.5-14.28,4.55-19.28,6.54-10.77,8.48-19.22,8.26-25.63,0-.47,0-.94,0-1.4a26.17,26.17,0,0,0-.56-5.83,15.34,15.34,0,0,1,.92,8.34c-2.35,12.06,3.93,90.29,3.93,90.29s5.1,17.52,15.7,22.57Zm-249.08-5.55L521,675.85s11.39-5.45,12.57-18.68l8.05-63.23a64.44,64.44,0,0,0,2.15,8c1.42,4.15,2,10.23,2.12,17.47-.31,35.29-12.32,93.84-12.32,93.84l.14,0c0,.17-.06.31-.08.42l-.85.05c-19.75-.6-65.78-2.32-91.54-5.89ZM454,728.27v.12h-6Z"
      transform="translate(-194.18 -69.27)" fill="url(#4e52bdea-3ee3-4982-ab0f-41ec51f7690b)"/>
    <path
      d="M646,391.06c5.24,9.18,12,18,21,21.17,2.83,1,6,1.57,8,4.12a14.74,14.74,0,0,1,2.43,6.27c3.27,16.06,1.08,33.81,7.5,48.5,5.2,11.89,15.15,19.63,23.06,29.22s14.11,23.93,9.32,36.05a23.53,23.53,0,0,1-15.85,13.78c-6.62,1.8-13.53,1.05-20.25-.14-8.86-1.57-17.88-4-25.26-10-12.77-10.38-18.17-29-24.85-45.57-3.14-7.76-7.31-15.89-14.21-18.66-7.9-3.18-16.79,2.13-21.92,9.92s-7.4,17.58-9.89,27c-2.8,10.64-6.34,21.68-13.71,28.64a23.07,23.07,0,0,1-24.64,4.92c-8.2-3.41-14.68-12.06-16.57-22.09-2.9-15.45,4.06-34-4.49-46.22-3.32-4.73-8.39-7.31-11.95-11.79-4.45-5.61-6.07-13.6-6.42-21.27-.75-16.38,4.86-34.63,17.51-41.41,3.38-1.81,7.15-2.76,10.12-5.4,10-8.89,5.28-30.72,15.63-39,3-2.41,6.76-3.08,10.38-3.59,10.14-1.4,20.21-1.56,30.4-1.53,6.59,0,13.38-2.2,19.88-1.72C628.72,353.57,637.51,376.19,646,391.06Z"
      transform="translate(-194.18 -69.27)" fill="#965d7b"/>
    <polygon points="264.1 652.85 267.93 698.22 535.82 696.62 530.71 647.09 440.14 636.55 281.32 646.14 264.1 652.85"
             fill="#575988"/>
    <polygon points="264.1 652.85 267.93 698.22 535.82 696.62 530.71 647.09 440.14 636.55 281.32 646.14 264.1 652.85"
             opacity="0.05"/>
    <path d="M634.55,753.82l-89.18,8.35s61.1,15.56,96.77,8Z" transform="translate(-194.18 -69.27)" fill="#f7c4a7"/>
    <path d="M634.55,753.82l-89.18,8.35s61.1,15.56,96.77,8Z" transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path
      d="M566.62,434.29s17.46,45.92-8.35,66.41-4.55,77.8-4.55,77.8l99.43-4.56s9.86-64.51,4.55-78.17c0,0-23.53,6.83-28.84-7.59s-9.11-53.89-9.11-53.89Z"
      transform="translate(-194.18 -69.27)" fill="#f7c4a7"/>
    <path
      d="M543.85,712.08s-83.87-1.9-108.53-8S389.4,727.26,398.13,744s33,28.08,33,28.08,124.86,6.07,151.8-11c0,0,10.25-29.22,0-34.91H452.77l96-1.9Z"
      transform="translate(-194.18 -69.27)" fill="#575988"/>
    <path
      d="M677.05,703.35s28.09,6.07,66.79-9.11c0,0,72.49-2.27,48.2,44s-158.63,34.92-158.63,34.92-8-17.08,0-21.63,77.42-33.78,99.05-32.26l-60,11.76Z"
      transform="translate(-194.18 -69.27)" fill="#575988"/>
    <path
      d="M623.19,456.38c-1.45-10.81-2.3-19.62-2.3-19.62H567.76a103.51,103.51,0,0,1,5.08,21.48,41.75,41.75,0,0,0,50.35-1.86Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <circle cx="401.47" cy="352.31" r="41.74" fill="#f7c4a7"/>
    <path
      d="M571.17,494.25s-50.09,9.49-56.16,22-5.31,55.41-5.31,55.41,2.27,70.2-15.18,78.93l-65.28,45.54s-46.29-40.6-57.68-16.7,54.65,32.64,54.65,32.64l73.62-22,23.53-15.18s11-5.31,12.14-18.22l9.87-78.17,39.47-11.39h63.75l8.54-24.38s15.75,12.62,13.47,24.38,3.8,88,3.8,88,4.93,17.07,15.18,22l59.2,21.25s62.61,6.07,66.79-21.25S754.09,681,754.09,681L707,644.53s-3.79-15.18-5.31-25.81-3.79-56.16-3.79-56.16,14.42-47.82-27.33-56.16l-53.89-16.7Z"
      transform="translate(-194.18 -69.27)" fill="#f7c4a7"/>
    <path
      d="M537.59,503.93l7.78,51.42s-8,23.91,0,47.44S535.5,714,535.5,714s93.74,30,151-4.56c0,0-42.12-88.8-24.29-118.4s.3-41.18.3-41.18l2.74-54.45-12.9-3.8-7.59,53.89s-52.37,36.43-91.08,4.56l-8.35-58.45Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M591.73,565a30.75,30.75,0,0,0-39.8-11.19C564.64,563.36,578.66,565.91,591.73,565Z"
          transform="translate(-194.18 -69.27)" fill="#fff" opacity="0.07"/>
    <path d="M613.24,559.35A30.7,30.7,0,0,1,653,548.16C640.32,557.67,626.31,560.21,613.24,559.35Z"
          transform="translate(-194.18 -69.27)" fill="#fff" opacity="0.07"/>
    <path
      d="M537.59,503.93l7.78,48.76s-8,23.91,0,47.44-9.87,111.19-9.87,111.19,93.74,30,151-4.55c0,0-42.12-88.8-24.29-118.4s.3-41.18.3-41.18l2.74-54.46-12.9-3.79-7.59,53.89s-52.37,36.43-91.08,4.55l-8.35-58.44Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path
      d="M537.21,503.55l8.16,49.9s-8,23.91,0,47.44-9.87,111.19-9.87,111.19,93.74,30,151-4.55c0,0-42.12-88.81-24.29-118.41s.3-41.17.3-41.17l2.74-54.46-12.9-3.79-7.59,53.89s-52.37,36.43-91.08,4.55l-8.35-58.44Z"
      transform="translate(-194.18 -69.27)" fill="#fc6681"/>
    <path
      d="M575.73,730.67,595.46,728s22.77-29.22,39.09-6.83c0,0,6.83,14.42,15.18,17.07a112.12,112.12,0,0,0,17.46,3.8l-33.78,9.49s-14.8,8.73-30.36,6.45a58.87,58.87,0,0,0-27.32,2.28Z"
      transform="translate(-194.18 -69.27)" fill="#f7c4a7"/>
    <path d="M553.91,606.77s35.67,5.31,41.74-4.17" transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M621.46,600.7s6.83,6.83,34.15,1.9" transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M552,494.82s26.19,3.8,31.12,1.52" transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M609.69,494.82s26.19,3.8,31.12,1.52" transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M578.57,727.83s7.22,34,0,35.2-17.45-5.6-17.45-6.36,4.55-22.39,4.55-22.39Z"
          transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path d="M577.82,727.83s7.21,34,0,35.2-17.46-5.6-17.46-6.36,4.55-22.39,4.55-22.39Z"
          transform="translate(-194.18 -69.27)" fill="#575988"/>
    <path
      d="M542.59,489.84c-3.19,7.39-4.32,16.11-.94,23.41,2.58,5.57,7.63,10.08,8.6,16.14,1.22,7.61-4.42,15.33-2.31,22.74.8,2.83,2.67,5.23,4.57,7.48,5.78,6.83,13.46,13.45,22.38,12.92,1.42-13.78-10.5-27.15-6.6-40.44,1.4-4.76,4.68-8.72,6.66-13.26,3.29-7.53,2.82-16.13,1.63-24.26-1.73-11.8-6.11-26.26-17.81-31.78-5.37-2.54-13-2.73-14.91,4.17-1.05,3.68,1.4,7.14,1.73,10.74C546,482.27,544.36,485.74,542.59,489.84Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path
      d="M542.59,488.71c-3.19,7.39-4.32,16.1-.94,23.41,2.58,5.56,7.63,10.08,8.6,16.13,1.22,7.61-4.42,15.33-2.31,22.74.8,2.83,2.67,5.24,4.57,7.49,5.78,6.82,13.46,13.44,22.38,12.91,1.42-13.78-10.5-27.15-6.6-40.44,1.4-4.76,4.68-8.72,6.66-13.26,3.29-7.53,2.82-16.13,1.63-24.26-1.73-11.8-6.11-26.26-17.81-31.78-5.37-2.54-13-2.72-14.91,4.17-1.05,3.68,1.4,7.14,1.73,10.74C546,481.13,544.36,484.6,542.59,488.71Z"
      transform="translate(-194.18 -69.27)" fill="#965d7b"/>
    <path
      d="M545.56,401.09s55.4-7.59,56.92,3.79,50.48,30,50.48,30l-12.15-30-22.39-37.19s-41-3-42.88,0S552,380.59,552,380.59Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path
      d="M545.56,400.33s55.4-7.59,56.92,3.79,50.48,30,50.48,30l-12.15-30-22.39-37.19s-41-3-42.88,0S552,379.83,552,379.83Z"
      transform="translate(-194.18 -69.27)" fill="#965d7b"/>
    <path
      d="M642,481.42a27,27,0,0,1,5.56,11.13c.92,3.92,1,8,1.83,11.92,1.86,8.35,7.39,15.37,13.15,21.69a23.67,23.67,0,0,0,6.73,5.66c2.64,1.3,6,1.57,8.44,0-2.32-1.91-3.58-4.82-4.41-7.7a39.45,39.45,0,0,1-1.54-12.41c.25-7,2.3-14.39-.61-20.77-1.68-3.69-4.81-6.49-8-9a43.09,43.09,0,0,0-7.14-4.69c-4.72-2.35-10.12-3.38-15.33-3.9C633.9,472.66,639.83,478.86,642,481.42Z"
      transform="translate(-194.18 -69.27)" opacity="0.1"/>
    <path
      d="M642,480.29a26.85,26.85,0,0,1,5.56,11.12c.92,3.92,1,8,1.83,11.92,1.86,8.35,7.39,15.37,13.15,21.69a23.67,23.67,0,0,0,6.73,5.66c2.64,1.3,6,1.57,8.44,0-2.32-1.91-3.58-4.81-4.41-7.7a39.45,39.45,0,0,1-1.54-12.41c.25-7,2.3-14.39-.61-20.77-1.68-3.68-4.81-6.48-8-9a42.52,42.52,0,0,0-7.14-4.7c-4.72-2.35-10.12-3.38-15.33-3.9C633.9,471.52,639.83,477.72,642,480.29Z"
      transform="translate(-194.18 -69.27)" fill="#965d7b"/>
  </svg>
)

export default EgoImage
import React from 'react'
import {getOhHangPieData, OhHangPieChart,} from '../common/ohhang-pie-chart/ohhang-pie-chart'
import EmptySpace from '../common/EmptySpace'
import {VictoryLineGraph} from '../common/score-line-graphs/VictoryLineGraph'
import {SaJooAnalysis} from '../../types/saJoo/SaJooAnalysis'
import {EumYang} from "../../enums/EumYang";
import moment from "moment-timezone";
import {getLunarDateFromMoment} from "../../functions/lunar/utils";
import HonorImage from "../common/HonorImage";
import EgoImage from "../common/EgoImage";
import ActivityImage from "../common/ActivityImage";
import AchieveImage from "../common/AchieveImage";
import StudyImage from "../common/StudyImage";
import PartnerImage from "../common/PartnerImage";
import ChildrenImage from "../common/ChildrenImage";
import ParentImage from "../common/ParentImage";
import SiblingImage from "../common/SiblingImage";

interface Props {
  code: string
  saJooAnalysis: SaJooAnalysis
}

const SaJooContents: React.FC<Props> = ({code, saJooAnalysis}) => {
  const m = moment.tz(saJooAnalysis.birthTime, "Asia/Seoul")
  const lunar = getLunarDateFromMoment(m)


  return (
    <div className={'container mx-auto max-w-screen-md p-4'}>
      <div>
        {/*<OhhangStickChart gan={Gan.GAB} ji={Ji.JA} ganOhHang={OhHang.MOK} jiOhHang={OhHang.SOO} ganSibSung={""}*/}
        {/*                  ganJiJiUnSung={""} ganIlJiUnSung={""} jiSibSung={""} jiUnSung={""} jiSinSal={""}*/}
        {/*                  points={saJooAnalysis.ohHangAnalysis.ohHangPoints}*/}
        {/*                  habPoints={saJooAnalysis.ohHangAnalysis.habHwaOhHangPoints}*/}
        {/*                  sibSungs={saJooAnalysis.ohHangAnalysis.ohHangSibSungs} from={""} to={""}/>*/}

        <h2>구매 코드</h2>
        <p className="mb-1">다시 보기를 위하여 아래 구매 코드를 별도로 적어 놓으시기 바랍니다.</p>
        <h3 className="text-blue-600ㅁ border-l-4 pl-4 py-1 border-blue-600 shadow mt-1">
          {code}
        </h3>
        <p className="text-xs text-gray-500">
          다시 보기 페이지에서 구매 코드를 이용하여 <span className="font-bold text-red-500">한 달간 사주 풀이를 다시 볼
          수 있습니다.</span> 구매 코드는 고객님의 기기에 저장되고 다시 보기 페이지에서
          확인할 수 있지만, <span className="font-bold text-blue-500">시크릿 모드에서 결제하셨거나, 방문 기록을 삭제한
          경우</span>에는 <span className="font-bold text-red-500">사라질 수 있습니다.</span>
        </p>

        <p className="text-xs text-blue-500 font-semibold">
          결제할 때 이용하신 웹 브라우저로 접속해야지만 구매 기록을 볼 수 있습니다.
          예를 들어 삼성 인터넷으로 결제한 후에 크롬 브라우저로 들어오시면 구매 기록이 없습니다.
        </p>
        <p className="text-xs text-blue-500 font-semibold">
          구매 코드가 단말에 저장되어 있지 않더라도 별도로 적어 놓은 구매 코드를 직접 기입하는 방법으로 다시 보실 수 있습니다.
        </p>
        <p className="text-xs text-red-500 font-semibold">
          구매 코드를 잃어버리신 경우 다시 보기를 하실 수 없습니다.
        </p>

        <h2>입력 정보</h2>
        <p className="bold">{saJooAnalysis.gender === EumYang.YANG ? "남성" : "여성"} 양력 {m.format("YYYY년 MM월 DD일 HH시 mm분")}</p>
        <p className="bold text-xs text-gray-600 mt-0">음력 {parseInt(lunar.year)}년 {parseInt(lunar.month)}월 {parseInt(lunar.day)}일 {lunar.leapMonth && '윤달'}</p>
        {/*<p className="bold mt-2">{saJooAnalysis.gender === EumYang.YANG ? "남성" : "여성"} </p>*/}


        <div className={'divider'}/>

        <h1 className="section-header">오행</h1>
        <p className="intro">
          사주는 나무, 불, 흙, 쇠, 물 다섯 가지 성분으로 이루어져 있습니다. 이
          다섯 가지 성분은 비율에 따라서 당신의 성격이나 삶의 태도에 영향을
          줍니다. 오행 파트에서는 오행의 분포를 분석하여 당신에 대해서
          간략히 설명합니다.
        </p>

        <h2>오행 그래프</h2>
        <div style={{width: '100%', height: 300}}>
          <OhHangPieChart
            data={saJooAnalysis.ohHangAnalysis.ohHangPoints.map((val, i) =>
              getOhHangPieData(val, i),
            )}
          />
        </div>
        <p>{saJooAnalysis.ohHangAnalysis.ohHangDescription}</p>
        <div className={'divider'}/>

        {/*<h1 className="section-header">특이사항</h1>*/}
        {/*<p className="intro">*/}
        {/*    사주풀이할 때, 사용하는 여러가지 속설들이 있습니다. 여기에서는*/}
        {/*    당신의 사주가 어떠한 속설들에 해당하는지에 대해서 알려 드립니다.*/}
        {/*</p>*/}
        {/*<EmptySpace height={'10px'}/>*/}
        {/*{saJooAnalysis.unusuals.map((val, i) => (*/}
        {/*    <div key={i}>*/}
        {/*        <h2>{val.name}</h2>*/}
        {/*        <p>{val.description}</p>*/}
        {/*        <EmptySpace height={'10px'}/>*/}
        {/*    </div>*/}
        {/*))}*/}
        {/**/}
        {/**/}
        {/*<div className={'divider'}/>*/}
        <div>
          <h1 className="section-header">자아</h1>
          <div className="max-w-md mx-auto">
            <EgoImage/>
          </div>
          <p className="intro">
            자신감, 자존감, 마음의 안정성과 관련된 수치입니다.
            정서적으로 안정된 삶, 대인관계 등에 영향을 미칩니다.
            추진력, 결단성과도 연관이 있습니다. 자아 지수가 높은 시기에는 다른
            사람에게 크게 휘둘리지 않으며 마음이 안정되어 있습니다. 반면, 자아
            점수가 낮은 시기에는 정신적, 육체적 스트레스로 힘든 시간을 보낼 수 있습니다.
            건강에도 문제가 생길 수 있습니다. 일생에서 자아 지수가 가장 낮은 시기에는 큰
            시련을 겪을 수 있으니 주의가 필요합니다.
          </p>
          <EmptySpace height={'10px'}/>
          <p className={'description'}>
            {saJooAnalysis.egoAnalysis.description}
          </p>
          <EmptySpace height={'10px'}/>
          <h2>시기에 따른 자아 지수</h2>
          <p className="intro">
            10세부터 89세까지의 자아 지수를 연도별로 나타냅니다. 자아 지수가
            높을 때는 마음이 안정적이고 외부의 영향에 크게 흔들리지 않습니다.
            반면 자아 지수가 낮은 시기에 힘든 일을 겪게 되면
            심리적으로 힘든 시간을 보낼 수 있습니다.
          </p>
          <EmptySpace height={'10px'}/>
          <VictoryLineGraph
            title={'자아'}
            data={saJooAnalysis.egoAnalysis.scores}
            tickType={'year'}
          />
          <EmptySpace height={'10px'}/>
        </div>
        <div className={'divider'}/>

        <div>
          <h1 className="section-header">활동</h1>
          <div className="max-w-md mx-auto">
            <ActivityImage/>
          </div>
          <p className="intro">
            활동 파트에서는 당신이 얼마나 활발하고 분주한 삶을 살 것인지를
            분석합니다. 활동 지수가 높은 시기에는 일도 많고 분주히 살아가게 됩니다.
            직업이 없더라도 노는 것이라도 열심히 하게 됩니다. 활동 지수가 높은 시기에
            사회적, 경제적으로 왕성하게 활동하게 됩니다.
          </p>
          <EmptySpace height={'10px'}/>
          <p className={'description'}>
            {saJooAnalysis.actAnalysis.description}
          </p>
          <EmptySpace height={'10px'}/>
          <h2>시기에 따른 활동 지수</h2>
          <p className="intro">
            10세 부터 89세 까지의 활동 지수를 연도별로 나타냅니다. 활동 지수가
            높을 때는 활발히 활동하게 됩니다. 가장 활발하게 일하는 시기인
            20대부터 50대 사이에 활동 지수가 높은 것이 좋습니다. 너무 어린
            시절에 활동 지수가 높으면 실력을 쌓기보다는 나가서 놀기 좋아하는
            타입일 수 있습니다. 말년까지 활동 지수가 높다면 노년에도 쉬지 못하고
            고단하게 일하게 되는 수가 있습니다.
          </p>
          <EmptySpace height={'10px'}/>
          <VictoryLineGraph
            title={'활동'}
            data={saJooAnalysis.actAnalysis.scores}
            tickType={'year'}
          />
          <EmptySpace height={'10px'}/>
        </div>

        <div className={'divider'}/>

        <div>
          <h1 className="section-header">성취</h1>
          <div className="max-w-md mx-auto">
            <AchieveImage/>
          </div>
          <p className="intro">
            성취는 당신이 노력하여 이룬 결과가 세상으로부터 얼마나 인정받고
            보상 받을 수 있는지를 나타냅니다. 성취가 높은 사람은 자신이 만들어낸
            결과가 세상으로부터 충분히 인정받고 보상받을 수 있습니다. 하지만
            성취가 낮은 사람은 열심히 일하고 노력하여도 그에 따른 보상이 좋지
            않을 확률이 높습니다.
            <br/>
            <br/>
            누구보다 열심히 치열하게 살더라도 별로 얻는 것이 없는 것은 사주에
            나타나는 성취가 낮기 때문에 그럴 수 있습니다. 성취가 낮은 사람은
            개인 사업이나 프리랜서보다는 최대한 안정적인 직장 생활을 할 수
            있도록 하는 것이 좋습니다.
          </p>
          <EmptySpace height={'10px'}/>

          <p className={'description'}>
            {saJooAnalysis.wealthAnalysis.description}
          </p>
          <EmptySpace height={'10px'}/>
          <h2>시기에 따른 성취 지수</h2>
          <p className="intro">
            10세부터 89세까지의 성취운을 연도별로 나타냅니다. 성취 지수가 높을
            때 최대한 경제 활동을 하는 것이 좋습니다. 재운이 높더라도
            경제활동을 하지 않으면 얻을 것이 없습니다.
            <br/>
            성취가 높게 유지되는 시기가 10년 이상 지속되는 구간이 있다면 그
            시기에 사업을 하면 잘 풀릴 수 있습니다.
            성취 지수가 낮은 시기에는 열심히 일해도 좀처럼 얻는 것이 없을 수 있습니다.
            평범한 직장 생활을 하는 경우에는 성취 지수에 따른 기복이 크지 않지만,
            사업을 하는 경우에는 클 수 있습니다.
          </p>
          <EmptySpace height={'10px'}/>
          <VictoryLineGraph
            title={'성취'}
            data={saJooAnalysis.wealthAnalysis.scores}
            tickType={'year'}
          />

          <EmptySpace height={'10px'}/>
        </div>
        <div className={'divider'}/>
        <div>
          <h1 className="section-header">명예</h1>
          <div className="max-w-md mx-auto">
            <HonorImage/>
          </div>
          <p className="intro">
            당신이 사회로부터 얼마나 많은 명예를 얻을지, 혹은 얼마나 높은 지위에
            오를 수 있을지 분석합니다. 명예운은 당신이 사회적 규범을 얼마나 잘
            지키는지와도 연관되어 있습니다. 또한 세상의 이목과 평판에 얼마나
            신경 쓰는지와도 관련이 있습니다. 명예운이 높은 경우, 올바르게
            사회생활을 하고 큰 구설수 없는 좋은 평판을 얻을 수 있습니다. 혹은,
            높은 명예, 높은 직위를 얻는 것과도 연관이 있습니다.
          </p>
          <EmptySpace height={'10px'}/>
          <p className={'description'}>
            {saJooAnalysis.honorAnalysis.description}
          </p>
          <EmptySpace height={'10px'}/>
          <h2>시기에 따른 명예 지수</h2>
          <p className="intro">
            10세부터 89세까지의 명예 지수를 연도별로 나타냅니다. 명예 지수가
            높을 때 사회로부터 좋은 평판을 얻고 올바르게 살아갈 수 있습니다.
            명예 지수가 낮을 때는 구설에 오르거나 체면을 구기는 일이 생길 수 있습니다.
            평범한 직장 생활을 하는 경우에는 명예 지수에 따른 기복이 크게 드러나지 않을 수 있으나
            정치 등 대외 활동을 하는 경우에는 강력하게 나타날 수 있습니다.
          </p>
          <VictoryLineGraph
            title={'명예'}
            data={saJooAnalysis.honorAnalysis.scores}
            tickType={'year'}
          />

          <EmptySpace height={'10px'}/>
        </div>

        <div className={'divider'}/>

        <div>
          <h1 className="section-header">공부</h1>
          <div className="max-w-md mx-auto">
            <StudyImage/>
          </div>
          <p className="intro">
            여기에서 공부는 학교에서 배우는 국영수만을 의미하지 않습니다. 자신의
            실력을 키우기 위한 모든 행위를 공부라 칭합니다. 예를 들어 운동선수가
            열심히 연습하는 것도 공부에 해당합니다. 가수가 노래 연습을 하는 것도
            공부입니다. 공부에 대한 적성이 높을 경우 전문 분야에서 높은 실력을
            쌓고 대성할 수 있습니다. 예를 들어 요리사가 되더라도 평범한 요리사가
            있고 실력이 절륜한 요리사가 있을 수 있습니다. 공부 적성이 높은 경우,
            실력이 절륜한 요리사가 될 확률이 높습니다.
          </p>
          <EmptySpace height={'10px'}/>
          <p className={'description'}>
            {saJooAnalysis.studyAnalysis.description}
          </p>
          <EmptySpace height={'10px'}/>
          <h2>시기에 따른 공부 지수</h2>
          <p className="intro">
            10세부터 89세까지의 공부 지수를 표시합니다. 특히 어린 시절 (8세부터
            20세)에 공부 지수가 높은 것이 좋습니다. 어린 시절 동안 충분한 실력을
            쌓을 수 있습니다. 뒤늦은 나이에 공부 지수가 높게 나온다면 늦게서야
            자신을 실력을 쌓고 싶은 마음이 생길 수도 있습니다. 평생 공부 지수가
            높게 유지되는 사람은 특정 분야에서 대가가 될 확률이 높습니다.
          </p>
          <VictoryLineGraph
            title={'공부'}
            data={saJooAnalysis.studyAnalysis.scores}
            tickType={'year'}
          />

          <EmptySpace height={'10px'}/>
        </div>

        <div className={'divider'}/>
        <h1 className="section-header">배우자</h1>
        <div className="max-w-md mx-auto">
          <PartnerImage/>
        </div>
        <p className="intro">
          당신의 사주팔자에 들어나 있는 배우자에 대해서 풀이합니다.
        </p>
        <EmptySpace height={'10px'}/>
        <h2>배우자들</h2>
        <p className={'description'}>
          {`명주의 사주에는 ${saJooAnalysis.mateAnalysis.mateCount}명의 배우자감이 있습니다.`}
          {saJooAnalysis.mateAnalysis.mateCount > 1 && (
            <span>
              {' '}
              여러 명의 배우자감이 나온다고 해서 여러 사람을 사귀게 되는 것을
              의미하지는 않습니다. (물론 그럴 확률이 높아지기는 합니다) 다만,
              배우자감이 여러명이라면 연애하고 결혼하는데에 큰 문제는 없을
              것입니다. 만일 모든 배우자감이 좋은 배우자감이라면 누구를 만나든
              크게 걱정하지 않아도 좋습니다. 하지만, 매우 좋지 않은 배우자감과
              좋은 배우자감이 섞여 있다면 연애할 때 상대방이 어디에 속하는지 잘
              살펴보아야 합니다. 또한, 100%는 아니지만 인연은 순서대로 찾아올
              확률이 높습니다. 예를 들어 1번째 배우자감을 만난 후 헤어졌다면
              2번째 배우자감을 만날 수 있습니다.
            </span>
          )}
          {saJooAnalysis.mateAnalysis.mateCount === 0 && (
            <span> {saJooAnalysis.mateAnalysis.noMate}</span>
          )}
        </p>
        <EmptySpace height={'10px'}/>
        {saJooAnalysis.mateAnalysis.mateCount > 0 ? (
          saJooAnalysis.mateAnalysis.mates.map((des, i) => (
            <div key={i}>
              <h3>{`${i + 1}번 째`}</h3>
              <p className={'description'}>{des.description}</p>
              <EmptySpace height={'10px'}/>
              <h4>이 사람과의 인연</h4>
              <p className={'description'}>{des.relation}</p>
              <EmptySpace height={'10px'}/>

              <EmptySpace height={'10px'}/>
            </div>
          ))
        ) : (
          <p className={'description'}>{saJooAnalysis.mateAnalysis.mates[0]}</p>
        )}
        <EmptySpace height={'10px'}/>
        <h2>이혼 가능성</h2>
        <p className={'description'}>{saJooAnalysis.mateAnalysis.attackMate}</p>
        <EmptySpace height={'10px'}/>
        <h2>재혼 가능성</h2>
        <p className={'description'}>
          {saJooAnalysis.mateAnalysis.secondMarriage}
        </p>
        <EmptySpace height={'10px'}/>
        <h2>시기에 따른 인연 지수</h2>
        <p className={'intro'}>
          연인과 인연이 이루어질 지수를 나타냅니다. 인연 지수가 높을 때, 인연을
          만날 확률이 높아지며 결실을 볼 수 있습니다.
        </p>
        {/*<ScoreLineGraphs max={4} id={"인연"} scores={saJooAnalysis.mateAnalysis.mateProbabilities}/>*/}
        <VictoryLineGraph
          title={'인연'}
          data={saJooAnalysis.mateAnalysis.mateScores}
          tickType={'year'}
        />
        <div className={'divider'}/>

        <div>
          <h1 className="section-header">부모</h1>
          <div className="max-w-md mx-auto">
            <ParentImage/>
          </div>
          <p className="intro">
            당신의 사주팔자에 들어나 있는 부모 형제관계에 대해서 풀이합니다.
          </p>
          <EmptySpace height={'10px'}/>
          <h2>아버지</h2>
          <p className="intro">
            알고리즘에 의하여 자동으로 추출되는 사주풀이의 특성상 한 명이 아닌
            여러 명의 부친을 제시할 수 있습니다. 그중 한 분이 자신의 아버지에
            해당합니다. 간혹 혈연관계상의 아버지가 아닌 다른 사람의 손에 자란
            경우 그분을 대입하여 보시면 됩니다.
          </p>
          <EmptySpace height={'10px'}/>

          {saJooAnalysis.familyAnalysis.father.map((father, i) => (
            <div key={i}>
              <h3>{`${i + 1}번 째`}</h3>
              <p className={'description'}>{father.description}</p>
              <EmptySpace height={'10px'}/>
              <div className={'bold'}>이 사람과의 인연</div>
              <p className={'description'}>{father.relation}</p>

              <EmptySpace height={'10px'}/>
            </div>
          ))}
          <EmptySpace height={'10px'}/>
          <h2>어머니</h2>
          <p className="intro">
            알고리즘에 의하여 자동으로 추출되는 사주풀이의 특성상 한 명이 아닌
            여러 명의 가능성을 제시할 수 있습니다. 그중 한 분이 자신의 어머니에
            해당합니다. 간혹 혈연관계상의 어머니가 아닌 다른 사람의 손에 자란
            경우 그분을 대입하여 보시면 됩니다.
          </p>
          <EmptySpace height={'10px'}/>

          {saJooAnalysis.familyAnalysis.mother.map((mother, i) => (
            <div key={i}>
              <h3>{`${i + 1}번 째`}</h3>
              <p className={'description'}>{mother.description}</p>
              <EmptySpace height={'10px'}/>
              <div className={'bold'}>이 사람과의 인연</div>
              <p className={'description'}>{mother.relation}</p>

              <EmptySpace height={'10px'}/>
            </div>
          ))}
          <div className={'divider'}/>
          <div>
            <h1 className="section-header">형제 자매</h1>
            <div className="max-w-md mx-auto">
              <SiblingImage/>
            </div>
            <p className="intro">
              형제, 자매 항목에서 표시되는 사람들은 실제 형제자매일 수도 있지만,
              평생을 함께하는 친구를 나타내기도 합니다. 일반적으로 형제, 자매가
              많이 표시되는 사람인 경우 대인 관계가 넓은 경우가 많습니다.
            </p>
            <EmptySpace height={'10px'}/>

            {saJooAnalysis.familyAnalysis.siblings.map((sibling, i) => (
              <div key={i}>
                <h3>{`${i + 1}번 째`}</h3>
                <p className={'description'}>{sibling.description}</p>
                <EmptySpace height={'10px'}/>
                <h4>이 사람과의 인연</h4>
                <p className={'description'}>{sibling.relation}</p>

                <EmptySpace height={'10px'}/>
              </div>
            ))}
          </div>

          <div className={'divider'}/>

          <div>
            <h1 className="section-header">자녀</h1>
            <div className="max-w-md mx-auto">
              <ChildrenImage/>
            </div>
            <p className="intro">
              사주팔자에 드러난 자식 자리가 한명 한명씩 실제 자식과 일치하는
              것은 아닙니다. 사주팔자에 자식 자리가 여럿 있어도 실제로는 자식이
              한 명만 있을 수도 있고, 사주팔자에 자식 자리가 없어도 자식이 여럿
              있을 수 있습니다. 사주팔자에 드러난 자식 자리는 그러한 자식을
              얻을 가능성이 높다는 정도로 보는 것이 옳습니다.
            </p>
            <EmptySpace height={'10px'}/>

            {saJooAnalysis.familyAnalysis.children.map((child, i) => (
              <div key={i}>
                <h3>{`${i + 1}번 째`}</h3>
                <p className={'description'}>{child.description}</p>
                <EmptySpace height={'10px'}/>
                <h4>이 사람과의 인연</h4>
                <p className={'description'}>{child.relation}</p>

                <EmptySpace height={'10px'}/>
              </div>
            ))}
          </div>
        </div>
        <div className={'divider'}/>
        <div>
          <h1 className="section-header">신살</h1>
          <p className="intro">
            신살은 당신의 사주팔자에 놓여있는 좋은 길신과 나쁜 흉살을
            의미합니다. 신살 파트에서는 당신의 사주에 어떤 신살이 놓여 있는지
            알려 드립니다.
          </p>
          <EmptySpace height={'10px'}/>
          {saJooAnalysis.sinSalAnalysis.sinSals.map((sinSal, index) => (
            <div key={index}>
              <h2>{sinSal.name}</h2>
              <p className={'description'}>{sinSal.description}</p>
              <EmptySpace height={'10px'}/>
            </div>
          ))}
        </div>
        <div className={'divider'}/>

        {/*<div>*/}
        {/*    <h1>연도별 간략 운세</h1>*/}
        {/*    {saJooAnalysis.yearInfos.map((val,i)=> (*/}
        {/*        <YearLuck yearInfo={val} key={i}/>*/}
        {/*    ) )}*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default SaJooContents

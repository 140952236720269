import React from 'react'


const HonorImage = () => (
  <svg id="b5912104-82f5-4c61-b8ec-11e896cab884" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 1081 840.51"><title>awards</title>
    <path
      d="M1039.16,719.07C1029,708.26,1005.49,699,974,692.87V553.55h-1.44v-1.44h-26v1.44H905.71c3.66-21.08,15.87-94.58,13.41-124.1,0,0,31.74-86.57,5.77-158.72,0,0,26,5.77,28.86-23.09,2.33-23.3,29.08-74.78-1.64-104.4-7.21-7.56-17.93-13.81-33.41-18l-7.51-21c4.9-7.75,7.93-18.21,7.93-29.74,0-15.53-5.46-29.14-13.64-36.78-4.71-5-10.46-8-16.67-8a21.38,21.38,0,0,0-13,4.66c.54.41,1.06.85,1.58,1.31-9.49,7.09-16,21.78-16,38.76,0,12.85,3.75,24.36,9.68,32.29l-3.91,25.43S786.36,112,821,247.64c0,0,8.66,23.09,23.09,23.09,0,0-8.66,103.89,5.77,141.41,0,0-5.77,92.35,5.78,112.55v28.86H783.48v142.9c-36.94,8.46-60.61,21.45-60.61,36,0,25.51,72.36,46.18,161.62,46.18S1046.1,758,1046.1,732.48C1046.1,727.82,1043.66,723.32,1039.16,719.07Z"
      transform="translate(-59.5 -29.75)" fill="#f2f2f2"/>
    <path
      d="M470.42,734.21c-9.43-10.06-31.33-18.67-60.68-24.38V580.18H408.4v-1.34H384.23v1.34h-38c3.41-19.61,14.77-88,12.48-115.48,0,0,29.54-80.57,5.37-147.71,0,0,24.17,5.37,26.86-21.48,2.17-21.68,27.06-69.59-1.53-97.15-6.71-7-16.68-12.85-31.08-16.73l-7-19.58c4.57-7.22,7.38-16.94,7.38-27.68,0-14.45-5.07-27.11-12.69-34.22-4.38-4.66-9.73-7.4-15.51-7.4a19.86,19.86,0,0,0-12.08,4.34c.5.38,1,.79,1.47,1.21-8.83,6.6-14.9,20.27-14.9,36.07,0,12,3.5,22.67,9,30l-3.64,23.66s-75.19-18.8-43,107.43c0,0,8.06,21.48,21.49,21.48,0,0-8.06,96.68,5.37,131.6,0,0-5.37,85.94,5.37,104.74v26.85H232.49v133c-34.37,7.87-56.4,20-56.4,33.53,0,23.73,67.34,43,150.4,43s150.39-19.24,150.39-43C476.88,742.35,474.61,738.16,470.42,734.21Z"
      transform="translate(-59.5 -29.75)" fill="#f2f2f2"/>
    <path
      d="M691.69,727.44c-7-7.5-23.34-13.91-45.2-18.16V612.72h-1v-1h-18v1h-28.3c2.54-14.61,11-65.56,9.3-86,0,0,22-60,4-110,0,0,18,4,20-16,1.62-16.15,20.16-51.83-1.14-72.36-5-5.24-12.42-9.57-23.15-12.46L603,301.29a39,39,0,0,0,5.5-20.62c0-10.76-3.78-20.19-9.46-25.49-3.26-3.47-7.24-5.51-11.54-5.51a14.8,14.8,0,0,0-9,3.24c.38.28.74.58,1.1.9-6.58,4.91-11.1,15.09-11.1,26.86,0,8.91,2.61,16.89,6.72,22.38l-2.72,17.63s-56-14-32,80c0,0,6,16,16,16,0,0-6,72,4,98,0,0-4,64,4,78v20h-50v99c-25.6,5.86-42,14.87-42,25,0,17.68,50.15,32,112,32s112-14.33,112-32C696.5,733.5,694.81,730.38,691.69,727.44Z"
      transform="translate(-59.5 -29.75)" fill="#f2f2f2"/>
    <path
      d="M1099.66,743.82c-4.14-4.42-13.75-8.2-26.63-10.71v-56.9h-.59v-.59h-10.61v.59h-16.68c1.5-8.61,6.49-38.64,5.48-50.69,0,0,13-35.37,2.36-64.84,0,0,10.61,2.36,11.79-9.43,1-9.51,11.88-30.54-.67-42.64-2.95-3.09-7.33-5.64-13.65-7.34l-3.07-8.6a22.9,22.9,0,0,0,3.24-12.15c0-6.34-2.23-11.9-5.57-15a9.35,9.35,0,0,0-6.81-3.25,8.74,8.74,0,0,0-5.3,1.91,7.81,7.81,0,0,1,.64.53c-3.87,2.89-6.53,8.89-6.53,15.83a22.28,22.28,0,0,0,4,13.19l-1.6,10.39s-33-8.25-18.86,47.15c0,0,3.54,9.43,9.43,9.43,0,0-3.53,42.44,2.36,57.76,0,0-2.36,37.73,2.36,46v11.79H995.23v58.37c-15.09,3.45-24.76,8.76-24.76,14.71,0,10.42,29.56,18.87,66,18.87s66-8.45,66-18.87C1102.5,747.39,1101.5,745.55,1099.66,743.82Z"
      transform="translate(-59.5 -29.75)" fill="#f2f2f2"/>
    <path
      d="M239.66,743.82c-4.14-4.42-13.75-8.2-26.63-10.71v-56.9h-.59v-.59H201.83v.59H185.15c1.5-8.61,6.49-38.64,5.48-50.69,0,0,13-35.37,2.36-64.84,0,0,10.61,2.36,11.79-9.43.95-9.51,11.88-30.54-.67-42.64-3-3.09-7.33-5.64-13.65-7.34l-3.07-8.6a22.9,22.9,0,0,0,3.24-12.15c0-6.34-2.23-11.9-5.57-15a9.35,9.35,0,0,0-6.81-3.25,8.74,8.74,0,0,0-5.3,1.91,7.81,7.81,0,0,1,.64.53c-3.87,2.89-6.53,8.89-6.53,15.83A22.28,22.28,0,0,0,171,493.71l-1.6,10.39s-33-8.25-18.86,47.15c0,0,3.54,9.43,9.43,9.43,0,0-3.53,42.44,2.36,57.76,0,0-2.36,37.73,2.36,46v11.79H135.23v58.37c-15.09,3.45-24.76,8.76-24.76,14.71,0,10.42,29.56,18.87,66,18.87s66-8.45,66-18.87C242.5,747.39,241.5,745.55,239.66,743.82Z"
      transform="translate(-59.5 -29.75)" fill="#f2f2f2"/>
    <path
      d="M59.5,748.13h1081s-40,108.72-142.47,70-163.71,7.5-219.95,28.74-168.71,37.5-197.45,5-55-47.49-156.21-35-167.47,38.74-180,3.75-15-46.24-86.23-40S59.5,748.13,59.5,748.13Z"
      transform="translate(-59.5 -29.75)" fill="#2f2e41"/>
    <path
      d="M694.24,399.7c-12.14,20.64-14,45.48-18.09,69.06-6.42,36.54-19,71.78-25.34,108.34-2.21,12.79-3.64,25.76-7.15,38.25-4.82,17.16-13.42,33-21.49,48.85-13.74,27.08-26.21,55.24-31.34,85.17-1.6,9.33-.35,22,8.89,24,2.64.59,5.61.12,7.86,1.62,1.32.87,2.16,2.28,3.22,3.45,4.06,4.48,11.05,5,16.85,3.37s10.86-5.21,16.24-8c21.36-11,46.68-9.69,70.64-8.13,4.41.28,9.36.83,12.15,4.27,1.41,1.75,2,4,3.05,6,3.72,7.25,13.54,10.62,20.92,7.18,2.76-1.29,5.13-3.36,8-4.42,8.77-3.27,18,3.82,27.39,3.87,13.5.06,23.66-14.24,37.15-14.73,9.2-.33,17.61,5.94,26.81,6,6.9,0,13.26-3.48,19.26-6.87l41-23.16a170.64,170.64,0,0,0-39.73-39.68c-11.29-8-14.34-24-21.23-36-4.55-7.94-9.15-16-11.37-24.87-1.6-6.4-1.92-13.06-3.5-19.47-2.19-8.84-6.7-16.89-10.28-25.26-14.2-33.16-13.72-71-26.73-104.65-3.66-9.48-8.37-18.55-11.62-28.18-4.28-12.68-5.95-26.12-10.27-38.78s-12-25.09-24.25-30.54a48.75,48.75,0,0,0-16.72-3.63C720.25,391.84,707.52,394.3,694.24,399.7Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <rect x="631.06" y="201.78" width="57.04" height="76.06" fill="#fbc661"/>
    <path
      d="M746.06,290.53c-2.9-.45-5.86,0-8.79,0-7.21-.09-9.26,8.62-16.45,9.33-8.77.86-20.93-4.69-28.26.21a28.39,28.39,0,0,1-4.24,2.44c-6,2.64-13.33,1.66-18.82,5.25S662,318.44,662,325a17.38,17.38,0,0,0,1,6.91c.77,1.87,2.05,3.49,2.82,5.36.54,1.32.82,2.73,1.27,4.09a33.48,33.48,0,0,0,5,8.64l12.7,17.67c3.33,4.64,2,10.38,2.54,16.07.4,4.37,1.42,8.69,1.41,13.07s-1.21,9-4.48,12q11.28-.69,22.49-2.07c6.16-.76,12.3-1.67,18.49-2.11,13-.92,26.06.27,39,1.46-2.14-2.66-3.11-6.37-3.64-9.74-1.76-11.18-2.87-22.89.81-33.59a79.28,79.28,0,0,1,4.15-9.34l3.95-7.95c5.59-11.24,10.81-22.76,12-35.26a19,19,0,0,0-1.11-9.5c-1.73-3.87-5.47-6.59-9.5-7.91a33.39,33.39,0,0,0-12.6-1.1C754.36,291.89,750,291.13,746.06,290.53Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M705,285.27c.91,2.38,2,4.93,1.29,7.39-1.32,4.88-8.06,5.87-11,10a2.13,2.13,0,0,0-.45,2.11,2.32,2.32,0,0,0,.54.63c5.49,4.78,13.43,5.19,20.71,5.11,10.61-.1,21.94-1,30.35-7.43,2.35-1.8,4.56-4.8,3.36-7.51-.93-2.07-3.38-2.85-5.41-3.87-4.55-2.29-7.81-6.65-9.61-11.42s-2.26-9.92-2.34-15a4.25,4.25,0,0,0-.54-2.5c-.7-1-2-1.15-3.23-1.23a96.57,96.57,0,0,0-20.53.82c-1.92.28-5.58.54-6.93,2.11s-.57,3.43-.31,5.14A76.79,76.79,0,0,0,705,285.27Z"
      transform="translate(-59.5 -29.75)" fill="#fbb3b2"/>
    <path
      d="M705,285.27c.91,2.38,2,4.93,1.29,7.39-1.32,4.88-8.06,5.87-11,10a2.13,2.13,0,0,0-.45,2.11,2.32,2.32,0,0,0,.54.63c5.49,4.78,13.43,5.19,20.71,5.11,10.61-.1,21.94-1,30.35-7.43,2.35-1.8,4.56-4.8,3.36-7.51-.93-2.07-3.38-2.85-5.41-3.87-4.55-2.29-7.81-6.65-9.61-11.42s-2.26-9.92-2.34-15a4.25,4.25,0,0,0-.54-2.5c-.7-1-2-1.15-3.23-1.23a96.57,96.57,0,0,0-20.53.82c-1.92.28-5.58.54-6.93,2.11s-.57,3.43-.31,5.14A76.79,76.79,0,0,0,705,285.27Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <circle cx="658.41" cy="220.21" r="25.61" fill="#fbb3b2"/>
    <path
      d="M742.33,778.75a35.36,35.36,0,0,0-5.79,3.22,16.45,16.45,0,0,0,15.28,1.2c1.94-.91,3.68-2.2,5.54-3.25C752.24,778.32,747.21,776.93,742.33,778.75Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M627.59,773.86c-4.33,2.22-8.46,4.93-12.93,6.78,4.06,2.42,9.49,2.53,14.16,1.18,5.8-1.69,10.86-5.21,16.23-8,11.72-6,24.64-8.35,37.86-9C663.75,764.18,644.44,765.2,627.59,773.86Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M871.68,704.13c-11.29-8-14.33-24-21.23-36-4.55-7.94-9.15-16-11.37-24.87-1.6-6.4-1.92-13.06-3.5-19.47-2.18-8.84-6.69-16.89-10.27-25.26-14.2-33.16-13.73-71-26.73-104.65-3.67-9.48-8.38-18.55-11.63-28.18-4.27-12.68-6-26.12-10.27-38.78s-12-25.09-24.25-30.54c-5.23-2.34-11-3.25-16.72-3.63a87,87,0,0,0-14.48.25A44.88,44.88,0,0,1,735,396.4c12.22,5.45,19.93,17.88,24.25,30.54s6,26.1,10.27,38.78c3.25,9.63,8,18.7,11.62,28.18,13,33.65,12.53,71.49,26.73,104.65,3.59,8.37,8.1,16.42,10.28,25.26,1.58,6.41,1.9,13.07,3.5,19.47,2.22,8.87,6.82,16.93,11.37,24.87,6.89,12,9.94,27.94,21.23,36A170.64,170.64,0,0,1,894,743.81l-41,23.16a70.8,70.8,0,0,1-10.75,5.26,30.3,30.3,0,0,0,9,1.61c6.89,0,13.25-3.48,19.25-6.87l41-23.16A170.45,170.45,0,0,0,871.68,704.13Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M806.87,767.89c-10.57.38-19.1,9.25-28.8,13a32.66,32.66,0,0,0,9.11,1.69c10.86,0,19.56-9.19,29.57-13.06A29.38,29.38,0,0,0,806.87,767.89Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M716.36,396.51V377.62h-9.17c.49-2.83,2.13-12.72,1.8-16.69,0,0,4.27-11.64.78-21.34a3.14,3.14,0,0,0,3.88-3.1c.38-3.88,5.82-13.58-4.66-16.3l-1.07-3a7.52,7.52,0,0,0,1.07-4c0-3.21-1.74-5.82-3.88-5.82s-3.88,2.61-3.88,5.82a7.34,7.34,0,0,0,1.3,4.35L702,321s-10.86-2.72-6.2,15.52c0,0,1.16,3.1,3.1,3.1,0,0-1.16,14,.78,19,0,0-.78,12.41.77,15.13v3.88h-9.7v19.21c-5,1.14-8.15,2.89-8.15,4.85,0,3.43,9.73,6.21,21.73,6.21s21.73-2.78,21.73-6.21C726.06,399.52,722.21,397.62,716.36,396.51Z"
      transform="translate(-59.5 -29.75)" fill="#60a5fa"/>
    <path
      d="M705.3,313a7.55,7.55,0,0,1-1.06,4l1.06,3c10.48,2.71,5.05,12.41,4.66,16.29a3.15,3.15,0,0,1-3.88,3.11c3.49,9.7-.78,21.34-.78,21.34.33,4-1.31,13.85-1.8,16.69H707c.49-2.84,2.14-12.72,1.81-16.69,0,0,4.26-11.64.77-21.34a3.15,3.15,0,0,0,3.88-3.11c.39-3.88,5.82-13.58-4.65-16.29l-1.07-3a7.55,7.55,0,0,0,1.07-4c0-3.21-1.74-5.82-3.88-5.82a2.87,2.87,0,0,0-1.75.63A6.57,6.57,0,0,1,705.3,313Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M716.17,396.31V377.43h-3.49v18.88c5.84,1.12,9.7,3,9.7,5.17,0,3.27-8.8,5.94-20,6.19l1.75,0c12,0,21.73-2.78,21.73-6.21C725.87,399.33,722,397.43,716.17,396.31Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M726.54,214c-8-2-16.6-3.53-24.22-.46a34.63,34.63,0,0,0-9.52,6.27c-2.59,2.25-5.11,4.74-6.49,7.89-1.65,3.8-1.46,8.1-1.56,12.24s-.65,8.58-3.4,11.67c-1.07,1.2-2.46,2.18-3.15,3.64-1,2-.34,4.47.64,6.5s2.29,3.95,2.74,6.17c.61,3.07-.58,6.25-2.27,8.89s-3.89,4.92-5.64,7.53-3.11,6.35-1.52,9.22c1,1.85,3.06,3,4.1,4.82.5.87.75,1.88,1.28,2.73,1.47,2.33,4.56,2.9,7.29,3.18,4.37.46,9.24.6,12.63-2.2,2.33-1.92,3.51-4.91,4.08-7.88s.59-6,1.12-9c.38-2.14,1-4.28.82-6.44-.25-2.88-1.92-5.41-3.23-8A21.86,21.86,0,0,1,697.58,259c.39-4.06,2.53-8.07,6.11-10,2.52-1.39,5.63-1.74,7.77-3.66,3.39-3,2.88-8.32,3.49-12.81a6.11,6.11,0,0,1,.95-2.81c1.06-1.44,3-1.83,4.8-2,4.19-.36,8.64.09,12.15,2.42a15.71,15.71,0,0,1,6.3,9.68,27.72,27.72,0,0,1-.21,11.75c.14-1.69,2.74-2.15,3.94-1s1.26,3.13.92,4.79c-1.4,7-7.74,12-9.87,18.8a17,17,0,0,0,3.66,16.3,4.63,4.63,0,0,0,2,1.42,8.56,8.56,0,0,0,2.43.12c3.19,0,5.93,2.19,8.36,4.27s5,4.31,8.24,4.56,6.73-2.67,5.77-5.72a6.18,6.18,0,0,0-1.63-2.38c-2-2.06-4.62-3.68-6-6.2-2.41-4.23-.94-9.52.58-14.15s3-9.93.53-14.14c-1.16-2-3.1-3.5-4-5.62a10.58,10.58,0,0,1-.77-3.86c-.21-4.84,1-9.77,0-14.49a17.7,17.7,0,0,0-8.33-10.88,24.07,24.07,0,0,0-6.68-2.79c-.91-.23-2.29-.18-2.93-.86s-.86-1.89-1.67-2.65C731.53,215.41,728.93,214.59,726.54,214Z"
      transform="translate(-59.5 -29.75)" fill="#fbc661"/>
    <path
      d="M742.88,245.19a2.7,2.7,0,0,0-3.19-.26,32.78,32.78,0,0,1-.69,6.32c.44-1.41,2.76-1.73,3.88-.63a3.38,3.38,0,0,1,.57.75,13.46,13.46,0,0,0,.35-1.39C744.14,248.32,744.09,246.38,742.88,245.19Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path d="M753,243.38a11.69,11.69,0,0,0,.09,1.19,49.33,49.33,0,0,0,.19-8.23A63.74,63.74,0,0,0,753,243.38Z"
          transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path d="M739,251.25a1.91,1.91,0,0,0-.06.33Z" transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M755.67,278.46a42.46,42.46,0,0,1,1.6-6c1.21-3.66,2.37-7.75,1.58-11.39a39.89,39.89,0,0,1-1.58,6C756.06,270.69,754.88,274.8,755.67,278.46Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M739.15,239.83a21.71,21.71,0,0,1,.4,2.37,24.32,24.32,0,0,0-.4-7.8,15.7,15.7,0,0,0-6.3-9.69c-3.51-2.32-8-2.78-12.15-2.42-1.78.16-3.74.55-4.8,2a6.07,6.07,0,0,0-.95,2.8c-.61,4.5-.1,9.79-3.49,12.81-2.14,1.93-5.25,2.28-7.77,3.67-3.58,2-5.72,6-6.11,10a17.43,17.43,0,0,0,.16,4.33,12.53,12.53,0,0,1,6-8.94c2.52-1.39,5.63-1.74,7.77-3.66,3.39-3,2.88-8.32,3.49-12.81a6.11,6.11,0,0,1,.95-2.81c1.06-1.44,3-1.83,4.8-2,4.19-.36,8.64.09,12.15,2.42A15.71,15.71,0,0,1,739.15,239.83Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M763.38,293.48a6.07,6.07,0,0,1-4.78,2c-3.19-.25-5.82-2.48-8.24-4.56s-5.17-4.23-8.36-4.27a8.94,8.94,0,0,1-2.43-.12,4.72,4.72,0,0,1-2-1.42,16.76,16.76,0,0,1-4.18-8.71,17,17,0,0,0,4.18,14.14,4.63,4.63,0,0,0,2,1.42,8.56,8.56,0,0,0,2.43.12c3.19,0,5.93,2.19,8.36,4.27s5,4.31,8.24,4.56,6.73-2.67,5.77-5.72A4.94,4.94,0,0,0,763.38,293.48Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M681.34,267a9.69,9.69,0,0,0,.24-4.51c-.45-2.21-1.77-4.13-2.74-6.16q-.3-.62-.54-1.26c0,.06-.07.12-.1.19-1,2-.34,4.47.64,6.5A41.8,41.8,0,0,1,681.34,267Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M703.18,277.14c-.17.89-.37,1.77-.53,2.66-.53,3-.56,6-1.12,9s-1.75,6-4.08,7.88c-3.39,2.79-8.26,2.66-12.63,2.2-2.73-.28-5.82-.86-7.29-3.18-.53-.86-.78-1.86-1.28-2.74-1-1.84-3.07-3-4.1-4.82-.07-.12-.13-.26-.19-.39a7.12,7.12,0,0,0,.19,5.83c1,1.85,3.06,3,4.1,4.82.5.87.75,1.88,1.28,2.73,1.47,2.33,4.56,2.9,7.29,3.18,4.37.46,9.24.6,12.63-2.2,2.33-1.92,3.51-4.91,4.08-7.88s.59-6,1.12-9c.38-2.14,1-4.28.82-6.44A10,10,0,0,0,703.18,277.14Z"
      transform="translate(-59.5 -29.75)" opacity="0.05"/>
    <path
      d="M665.92,315.14s-5,2.72-8.15,27.94-4.66,52-4.66,52,0,22.51,21.34,23.28c0,0,8.54,1.56,11.26,3.5s45.4-11.26,25.22-14-27.55-1.56-27.55-1.56-10.87-7-10.87-13.58S683.38,347,683.38,347Z"
      transform="translate(-59.5 -29.75)" fill="#fbb3b2"/>
    <path
      d="M665.84,334.92c.54,1.32.82,2.73,1.27,4.09a33.31,33.31,0,0,0,5,8.64c2.54,3.54,6.62,11.88,9.15,15.42a59.14,59.14,0,0,1,6.6-14.75l-21.92-35.5s-1.72.92-3.77,6.7c-.11,1.05-.17,2.1-.18,3.13a17.38,17.38,0,0,0,1,6.91C663.79,331.43,665.07,333.05,665.84,334.92Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M741,263a1.16,1.16,0,0,0-.77-1.09v-1.31a1.15,1.15,0,0,0,.77-1.09,1.17,1.17,0,1,0-1.55,1.09v1.31a1.16,1.16,0,0,0,0,2.19v1.3a1.16,1.16,0,0,0,0,2.19v1.31a1.16,1.16,0,1,0,.78,0v-1.31a1.16,1.16,0,0,0,0-2.19v-1.3A1.16,1.16,0,0,0,741,263Z"
      transform="translate(-59.5 -29.75)" fill="#eeeff3"/>
    <path
      d="M778.45,299.62a108.49,108.49,0,0,1,12.8,32.21c3.88,18.24,6.6,22.12,6.6,22.12s17.08,43.46-1.94,46.18-52.38,0-52.38,0-67.52,11.64-43.85-3.88S742,382.66,742,382.66s28.33-.77,32.21-3.49l7.37-.77-17.46-49.67Z"
      transform="translate(-59.5 -29.75)" fill="#fbb3b2"/>
    <path d="M760.41,328.14l8.14,19c5.5-11.09,11.83-24.68,13-37a48.83,48.83,0,0,0-.15-6.84,19.75,19.75,0,0,0-3.49-5.05Z"
          transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path d="M494.47,547s-19.63,40.86-6,55.28,22.84-56.88,22.84-56.88Z" transform="translate(-59.5 -29.75)"
          fill="#a0616a"/>
    <path
      d="M399.52,738.08l-22,27.64s-44.47,17.63-3.61,20c0,0,20-1.6,24-4.41s6.41-8.41,14.42-5.2,17.23-3.21,17.23-3.21,1.2-31.25-6.81-34.85S399.52,738.08,399.52,738.08Z"
      transform="translate(-59.5 -29.75)" fill="#36344a"/>
    <path
      d="M481.65,748.09l-5.21,22.84s-11.62,27.24,5.21,23.23,25.24-8.41,26.84-16.42,6-16.83,6-16.83-4.81-12.42-9.21-12.82S481.65,748.09,481.65,748.09Z"
      transform="translate(-59.5 -29.75)" fill="#36344a"/>
    <path
      d="M374.28,472.06s-14.42,99-4.4,153,22,109.37,22,109.37-14,32,34,10.82c0,0-3.2-79.33-12.82-110.17S418,562.6,422,561.4c0,0,16,80.53,31.65,107S478,756.51,478,756.51s33.66,4.4,33.26-4.41-6.41-83.33-26-109.77c0,0-16.83-44.07-6-89.74s-11.62-61.3-11.62-61.3L432,449.23l-35.65-8Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M480,301.5a11.35,11.35,0,0,1-1.79,3.62,10.36,10.36,0,0,1-2.92,2.19,56,56,0,0,1-11.11,4.77c-1,2,.62,4.25,2.16,5.87l4.52,4.78c2.64,2.78,5.28,5.56,8.13,8.12a70.76,70.76,0,0,0,18.53,12c3.54,1.57,8.11,2.69,10.95.06s2.13-7.25,3.85-10.68a1.93,1.93,0,0,0-.33-3.06c-2.32-2.29-2.79-5.91-2.3-9.13s1.8-6.26,2.55-9.43.89-6.71-.8-9.5a13.14,13.14,0,0,0-3.78-3.72,35,35,0,0,0-10.82-5.16c-2-.55-4-.92-6-1.51-1.24-.38-4.6-2.7-5.66-2.51C483,288.57,480.77,299.17,480,301.5Z"
      transform="translate(-59.5 -29.75)" fill="#a0616a"/>
    <path
      d="M480,301.5a11.35,11.35,0,0,1-1.79,3.62,10.36,10.36,0,0,1-2.92,2.19,56,56,0,0,1-11.11,4.77c-1,2,.62,4.25,2.16,5.87l4.52,4.78c2.64,2.78,5.28,5.56,8.13,8.12a70.76,70.76,0,0,0,18.53,12c3.54,1.57,8.11,2.69,10.95.06s2.13-7.25,3.85-10.68a1.93,1.93,0,0,0-.33-3.06c-2.32-2.29-2.79-5.91-2.3-9.13s1.8-6.26,2.55-9.43.89-6.71-.8-9.5a13.14,13.14,0,0,0-3.78-3.72,35,35,0,0,0-10.82-5.16c-2-.55-4-.92-6-1.51-1.24-.38-4.6-2.7-5.66-2.51C483,288.57,480.77,299.17,480,301.5Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M475.51,308.69c-.05-.79-.14-1.71-.81-2.14a2.25,2.25,0,0,0-1.28-.27c-9.46,0-17.13,7.44-23.08,14.78-12.54,15.45-22.43,32.89-31,50.86-5.27,11.07-10.16,23.52-6.77,35.31a36.25,36.25,0,0,0,4.73,9.83,53.81,53.81,0,0,0,10.24,11.53,11.49,11.49,0,0,0,5.32,2.9c3,.48,5.85-1.17,8.41-2.76q7.71-4.8,15.35-9.7c8.27-5.3,16.54-10.69,23.83-17.27s13.62-14.44,17.21-23.58c1.53-3.88,2.55-8,4.27-11.76,2.62-5.79,6.85-10.9,8.35-17.08.71-2.95.77-6,1.53-8.95.6-2.35,1.64-4.57,2.14-6.94s.38-5-1.13-6.94c-2.54-3.22-7.48-2.74-11.5-1.91-5.24,1.08-14.58,3.74-19.57.57C477.48,322.44,475.81,313.33,475.51,308.69Z"
      transform="translate(-59.5 -29.75)" fill="#eeeff3"/>
    <path
      d="M472.84,327.84l-5.61.4-4-1.61,3.21-9.61s11.3,1.28,12.46,4.84c0,0,.84,2.45,2.2,2.81s7-.44,8.17,2.36.8,18-6.81,16l-3.6-9.62-1.61-2.4S472.44,330.24,472.84,327.84Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M426.76,417.18s12.42-81.73,51.28-111c0,0,.4-7.61-6.41-6.81s-35.25-4.81-35.25-4.81l-26.84,52.09s-6,48.47-10.42,56.48-32.05,71.72-32.05,71.72,15.63,52.48,38.86-18c0,0-18,57.29,32.85,62.9s44.87-3.21,44.87-3.21l6-41.66V443.62l11.22-22,45.67-58.09s-31-42.92-36.92-39.09C509.63,324.4,471.23,385.13,426.76,417.18Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M442.39,297.79l-6-3.21s-18.43,6.81-24.84,33.26c0,0-15.62,11.21-26,19.63,0,0-14-26-14-34.86,0,0-23.24,15.63-28.45,11.62,0,0,14,64.5,40.47,59.69S420,363.49,420,363.49Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M531.33,365.9l15.22-2.41s9.62,12.82-7.61,31.65l-10.42,63.7s1.2,21.23-2.4,37.26-9.22,65.3-9.22,65.3-18.42-6.81-28.44-5.61L503.68,408Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path d="M370.68,317s-8.42-50.48-22.84-31.65,1.6,44.87,1.6,44.87Z" transform="translate(-59.5 -29.75)"
          fill="#a0616a"/>
    <circle cx="451.2" cy="257.43" r="32.45" fill="#a0616a"/>
    <path
      d="M536.64,252.17a22.92,22.92,0,0,1-2-2.93c-1.95-2.86-5-4.73-8-6.41-5.49-3-11.75-5.82-17.86-4.36-4.23,1-7.82,4-12.08,4.86s-9.32-.09-12.19,3.11-1.6,8.24-4.07,11.55c-1.37,1.83-3.91,3.51-3.22,5.69.29.92,1.14,1.57,1.53,2.46.69,1.56-.16,3.35-.2,5.06-.05,2.53,1.68,4.7,2.9,6.92s1.88,5.32,0,7.06a98,98,0,0,1,20.76-12.73,6.14,6.14,0,0,1-.78,2.93,96.62,96.62,0,0,0,11.91-2.53l-2.06,3.95,8.84-3.86c2.39-1.05,5.39-2,7.43-.4a6.72,6.72,0,0,1,1.9,3.31A43.94,43.94,0,0,1,531.51,287c.22,4.28,0,9,2.77,12.27a8.33,8.33,0,0,0,12.17-.08c.78-3-.39-6.1-.45-9.18-.09-4.49,2.17-8.71,2.58-13.18.53-5.65-2.81-9-4.33-14-.59-1.92-.23-4.19-1.36-6C541.43,254.59,538.45,254.09,536.64,252.17Z"
      transform="translate(-59.5 -29.75)" fill="#3f3d56"/>
    <path
      d="M348.05,343.69l11.57-19.43-9.44-5.62c2.24-2.62,10-11.78,12.07-16.06,0,0,11.52-9.36,13.87-21.48,0,0,3.12,2.94,5.9-.82s14.3-10.4,5.19-19.61l.73-3.73a9,9,0,0,0,3.55-3.46c2-3.31,1.77-7-.43-8.36s-5.59.3-7.56,3.61a8.81,8.81,0,0,0-1.32,5.26l-2.63,3.2s-9.52-9.45-15.9,12.16c0,0-.7,3.91,1.3,5.1,0,0-9.76,13.65-10.85,20,0,0-8.4,12.3-8.47,16l-2.38,4-10-5.94-11.76,19.77c-5.81-1.87-10.15-2-11.35,0-2.1,3.53,6.2,12.35,18.55,19.7s24.05,10.44,26.15,6.92C356.19,352.73,353.38,348.41,348.05,343.69Z"
      transform="translate(-59.5 -29.75)" fill="#60a5fa"/>
    <path
      d="M387.81,251a9.08,9.08,0,0,1-3.54,3.47l-.74,3.72c9.12,9.21-2.41,15.86-5.19,19.62a3.76,3.76,0,0,1-5.89.81c-2.35,12.12-13.87,21.48-13.87,21.48-2.09,4.29-9.83,13.45-12.07,16.06l3.59,2.14c2.24-2.61,10-11.78,12.07-16.06,0,0,11.52-9.36,13.87-21.48,0,0,3.12,2.94,5.9-.82s14.3-10.4,5.19-19.61l.73-3.73a9,9,0,0,0,3.54-3.46c2-3.3,1.78-7-.42-8.36a3.48,3.48,0,0,0-2.18-.43A7.86,7.86,0,0,1,387.81,251Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
    <path
      d="M348,343.37l11.57-19.43L356,321.8l-11.57,19.43c5.33,4.73,8.13,9,6.81,11.26-2,3.35-12.69.71-24.35-5.87.59.36,1.18.73,1.79,1.09,12.34,7.35,24.05,10.45,26.15,6.92C356.1,352.41,353.3,348.09,348,343.37Z"
      transform="translate(-59.5 -29.75)" opacity="0.1"/>
  </svg>
)

export default HonorImage